import { Col, Row } from 'antd';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { SettingDropdwon } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';

const Settings = React.memo(() => {
	const content = (
		<SettingDropdwon>
			<div className="setting-dropdwon">
				<Row gutter="10">
					<Col xs={24}>
						<NavLink to="/admin/changePassMail">
							<figure className="setting-dropdwon__single d-flex">
								<img style={{
									width: '24px',
									height: '24px',
								}} src={require('../../../static/img/icon/password-svgrepo-com.png')} alt="" />
								<figcaption>
									<Heading as="h5">Change Password</Heading>
								</figcaption>
							</figure>
						</NavLink>
					</Col>
				</Row>
			</div>
		</SettingDropdwon>
	);

	return (
		<div className="ninjadash-nav-actions__settings">
			<Popover placement="bottomRight" content={content} action="click">
				<Link className="ninjadash-nav-action-link">
					<ReactSVG src={require('../../../static/img/icon/setting.svg').default} />
				</Link>
			</Popover>
		</div>
	);
});

export default Settings;
