import actions from './actions';

const {
	INTEGRATION_AUTH_READ_BEGIN,
	INTEGRATION_AUTH_READ_SUCCESS,
	INTEGRATION_AUTH_READ_ERR,

	INTEGRATION_UPDATE_BEGIN,
	INTEGRATION_UPDATE_SUCCESS,
	INTEGRATION_UPDATE_ERR,

	INTEGRATION_CREATE_BEGIN,
	INTEGRATION_CREATE_SUCCESS,
	INTEGRATION_CREATE_ERR,
} = actions;

const initialState = {
	data: null,
	loading: false,
	error: null,
	successfull: false,
	authList: [],

};

const integrationReducer = (state = initialState, action) => {
	const { type, data, err, authList } = action;
	switch (type) {
		case INTEGRATION_AUTH_READ_BEGIN:
			return {
				...state,
				loading: true,
			};
		case INTEGRATION_AUTH_READ_SUCCESS:
			const al = state.authList ?? [];
			const _itemIndex = al.find((item) => item.key === authList.key);
			if (_itemIndex)
				al.splice(al.indexOf(_itemIndex), 1);
			al.push(authList);

			return {
				...state,
				authList: al,
				loading: false,
			};
		case INTEGRATION_AUTH_READ_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case INTEGRATION_UPDATE_BEGIN:
			return {
				...state,
				loading: true,
			};
		case INTEGRATION_UPDATE_SUCCESS:
			return {
				...state,
				data,
				loading: false,
				successfull: true,
			};
		case INTEGRATION_UPDATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
				successfull: false,
			};
		case INTEGRATION_CREATE_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
				successfull: false,
			};
		case INTEGRATION_CREATE_SUCCESS:
			return {
				...state,
				data,
				loading: false,
				successfull: true,
				error: null,
			};
		case INTEGRATION_CREATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
				successfull: false,
			};
		default:
			return state;
	}
};

export default integrationReducer;
