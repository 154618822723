import { Modal } from 'antd';
import Styled from 'styled-components';

const ModalStyledColord = (type, theme) => `
  .ant-modal-content, .ant-modal-header {
    background-color: ${type !== 'default' && theme[theme.mainContent]['main-background-light']} !important;
  }
  .ant-modal-header {
    // border-bottom: 1px solid ${type !== 'default' && theme[theme.mainContent]['light-border']};
    border-bottom: 1px solid  ${theme[theme.mainContent]['gray-border']};
  }
  .ant-modal-title {
    color: ${type !== 'default' && theme[theme.mainContent]['dark-text']};
  }
  .ant-modal-content{
    .ant-modal-close-x{
      svg{
        color: red;
      }
    }
    p{
      color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
    }
  }
  .ant-modal-footer button {
    background: ${theme[theme.mainContent]['main-background-light']};
    color: ${theme[theme.mainContent]['gray-text']};
    border: 1px solid ${theme[theme.mainContent]['gray-border']};
  }
  .ant-modal-footer{
    border-top: 1px solid ${theme[theme.mainContent]['gray-border']};
  }
`;

const ModalStyled = Styled(Modal)`    
  ${({ theme, type }) => type && ModalStyledColord(type, theme)}
`;

export { ModalStyled, ModalStyledColord };
