const actions = {
	INTEGRATION_AUTH_READ_BEGIN: 'INTEGRATION_AUTH_READ_BEGIN',
	INTEGRATION_AUTH_READ_SUCCESS: 'INTEGRATION_AUTH_READ_SUCCESS',
	INTEGRATION_AUTH_READ_ERR: 'INTEGRATION_AUTH_READ_ERR',

	INTEGRATION_UPDATE_BEGIN: 'INTEGRATION_UPDATE_BEGIN',
	INTEGRATION_UPDATE_SUCCESS: 'INTEGRATION_UPDATE_SUCCESS',
	INTEGRATION_UPDATE_ERR: 'INTEGRATION_UPDATE_ERR',

	INTEGRATION_CREATE_BEGIN: 'INTEGRATION_CREATE_BEGIN',
	INTEGRATION_CREATE_SUCCESS: 'INTEGRATION_CREATE_SUCCESS',
	INTEGRATION_CREATE_ERR: 'INTEGRATION_CREATE_ERR',

	integrationAuthReadBegin: () => {
		return {
			type: actions.INTEGRATION_AUTH_READ_BEGIN,
		};
	},

	integrationAuthReadSuccess: (authList) => {
		return {
			type: actions.INTEGRATION_AUTH_READ_SUCCESS,
			authList,
		};
	},

	integrationAuthReadErr: (err) => {
		return {
			type: actions.INTEGRATION_AUTH_READ_ERR,
			err,
		};
	},

	integrationUpdateBegin: () => {
		return {
			type: actions.INTEGRATION_UPDATE_BEGIN,
		};
	},

	integrationUpdateSuccess: (data) => {
		return {
			type: actions.INTEGRATION_UPDATE_SUCCESS,
			data,
		};
	},

	integrationUpdateErr: (err) => {
		return {
			type: actions.INTEGRATION_UPDATE_ERR,
			err,
		};
	},

	integrationCreateBegin: () => {
		return {
			type: actions.INTEGRATION_CREATE_BEGIN,
		};
	},

	integrationCreateSuccess: (data) => {
		return {
			type: actions.INTEGRATION_CREATE_SUCCESS,
			data,
		};
	},

	integrationCreateErr: (err) => {
		return {
			type: actions.INTEGRATION_CREATE_ERR,
			err,
		};
	},
};

export default actions;
