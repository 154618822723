import React from "react";

const CampaignTypeRender = ({ value, data }) => {
    if (value === undefined){
        return null;
    }
    return (
        <>
            <img
                src={require(`../../static/img/brands/${value.toLowerCase()}.png`)}
                alt={value}
                style={{width: '20px', height: '20px', marginRight: '5px'}}
            />
            {
                data.ts_name || data.rs_name ? <img src={require(`../../static/img/rs_ts.png`)}
                                                    style={{width: 20, height: 20, marginRight: '5px'}} alt={"rs_ts"}/> : null
            }
            {
                data.ts_name ?
                    <img src={require(`../../static/img/brands/${data.ts_name.toLowerCase()}.png`)}
                         style={{width: 20, height: 20}}
                         alt={data.ts_name}
                    /> : null
            }
            {
                data.rs_name ?
                    <img src={require(`../../static/img/brands/${data.rs_name.toLowerCase()}.png`)}
                         style={{width: 20, height: 20}}
                         alt={data.rs_name}
                    /> : null
            }
        </>
    );
}

export default CampaignTypeRender;