const actions = {
	DENEME_API_READ_BEGIN: 'DENEME_API_READ_BEGIN',
	DENEME_API_READ_SUCCESS: 'DENEME_API_READ_SUCCESS',
	DENEME_API_READ_ERR: 'DENEME_API_READ_ERR',

	DENEME_API_CREATE_BEGIN: 'DENEME_API_CREATE_BEGIN',
	DENEME_API_CREATE_SUCCESS: 'DENEME_API_CREATE_SUCCESS',
	DENEME_API_CREATE_ERR: 'DENEME_API_CREATE_ERR',

	DENEME_API_UPDATE_BEGIN: 'DENEME_API_UPDATE_BEGIN',
	DENEME_API_UPDATE_SUCCESS: 'DENEME_API_UPDATE_SUCCESS',
	DENEME_API_UPDATE_ERR: 'DENEME_API_UPDATE_ERR',

	DENEME_API_DELETE_BEGIN: 'DENEME_API_DELETE_BEGIN',
	DENEME_API_DELETE_SUCCESS: 'DENEME_API_DELETE_SUCCESS',
	DENEME_API_DELETE_ERR: 'DENEME_API_DELETE_ERR',

	denemeApiReadBegin: () => {
		return {
			type: actions.DENEME_API_READ_BEGIN,
		};
	},

	denemeApiReadSuccess: (data) => {
		return {
			type: actions.DENEME_API_READ_SUCCESS,
			data,
		};
	},

	denemeApiReadErr: (err) => {
		return {
			type: actions.DENEME_API_READ_ERR,
			err,
		};
	},

	denemeApiCreateBegin: () => {
		return {
			type: actions.DENEME_API_CREATE_BEGIN,
		};
	},

	denemeApiCreateSuccess: (data) => {
		return {
			type: actions.DENEME_API_CREATE_SUCCESS,
			data,
		};
	},

	denemeApiCreateErr: (err) => {
		return {
			type: actions.DENEME_API_CREATE_ERR,
			err,
		};
	},

	denemeApiUpdateBegin: () => {
		return {
			type: actions.DENEME_API_UPDATE_BEGIN,
		};
	},

	denemeApiUpdateSuccess: () => {
		return {
			type: actions.DENEME_API_UPDATE_SUCCESS,
		};
	},

	denemeApiUpdateErr: (err) => {
		return {
			type: actions.DENEME_API_UPDATE_ERR,
			err,
		};
	},

	denemeApiDeleteBegin: () => {
		return {
			type: actions.DENEME_API_DELETE_BEGIN,
		};
	},

	denemeApiDeleteSuccess: () => {
		return {
			type: actions.DENEME_API_DELETE_SUCCESS,

		};
	},

	denemeApiDeleteErr: (err) => {
		return {
			type: actions.DENEME_API_DELETE_ERR,
			err,
		};
	},
};

export default actions;
