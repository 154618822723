import React, { useCallback, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { Button, Form, Input, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	integrationAuthListRead,
} from '../redux/integrations/actionCreator';
import {integrationAccountCreate, integrationAccountListRead} from "../redux/integrationAccounts/actionCreator";

const AccountMenuModal = ({ companyKey, form, setInstance, state, setState }) => {
	const { authList, accountList, isLoading } = useSelector((rootState) => {
		return {
			authList: rootState.integrations.authList,
			accountList: rootState.integrations.accountList,
			isLoading: rootState.integrations.loading,
		};
	});

	const dispatch = useDispatch();

	const fetchAuthList = useCallback((key) => {
		dispatch(integrationAuthListRead(key));
	}, [dispatch]);

	const fetchAccountList = useCallback((key, auth_id) => {
		dispatch(integrationAccountListRead(key, auth_id));
	}, [dispatch]);

	useEffect(() => {
		fetchAuthList(companyKey);
	}, [companyKey]);

	const Stats = ({
		               nextStep,
		               previousStep,
		               totalSteps,
		               step,
	               }) => (
		<div className="ninjadash-authentication-content" style={{ marginTop: 10 }}>
			{step > 1 &&
				/*<button className='btn btn-default btn-block' onClick={previousStep}>Go Back</button>*/
				<Button className="btn btn-default btn-block"
				        onClick={previousStep}
				        type="secondary"
				        style={{
					        marginRight: 10,
				        }}
				>
					Go Back
				</Button>
			}
			{step < totalSteps ?
				(
					<Button className="btn btn-primary btn-block"
					        type="primary"
					        onClick={() => {
						        nextStep();
						        if (step === 1) {
							        fetchAccountList(companyKey, state.selectedAuth.id);
						        }
					        }}>
						Next
					</Button>
				)
				:
				<Button className="btn btn-primary btn-block"
				        form="form-add-account"
				        htmlType="submit"
				        type="primary"
				>
					Finish
				</Button>
			}
		</div>
	);

	const First = (props) => {
		const handleAuthChange = (value) => {
			const val = JSON.parse(value);
			setState(prevState => ({
				...prevState,
				selectedAuth: val,
			}));
		};

		return (
			<div className="ninjadash-authentication-content">
				{
					isLoading ? (
						<Spin />
					) : (
						<Select
							placeholder={'Select Your Auth'}
							size="large"
							value={state.selectedAuth?.alias}
							onChange={handleAuthChange}
							options={authList?.filter((k) => k.key === companyKey)[0]?.rest.map((v) => (
								{ label: v.alias, value: JSON.stringify(v) }
							))}
							className="ninjadash-fullwidth-select"
						/>
					)
				}
				{
					state.selectedAuth && (
						<Stats step={1} {...props} />
					)
				}
			</div>
		);
	};

	const Second = props => {

		const onAccountChange = (e) => {
			const value = JSON.parse(e);
			setState(prevState => ({
				...prevState,
				selectedAccount: value,
			}));
		};

		return (
			<div className="ninjadash-authentication-content">
				{
					isLoading ? (
						<Spin />
					) : (
						<Select placeholder={'Select Your Account'}
						        size="large"
						        onChange={onAccountChange}
						        value={state.selectedAccount?.name}
						        options={accountList?.map((v) => (
							        { label: v.name, value: JSON.stringify(v) }
						        ))}
						        className="ninjadash-fullwidth-select" />
					)
				}
				{
					state.selectedAccount && (
						<Stats step={2} {...props} />
					)
				}
			</div>
		);
	};

	const Last = (props) => {
		const submit = useCallback((values) => {
			const auth_id = state.selectedAuth.id;
			const alias = values.alias;
			const name = values.name;
			const account_id = state.selectedAccount.account_id;
			const currency = values.currency;
			const utc = values.utc;

			const sendObj = {
				auth_id,
				alias,
				name,
				account_id,
				currency,
				utc,
			};

			dispatch(integrationAccountCreate({
				...sendObj,
			}, companyKey));
		});

		return (
			isLoading ? (
				<Spin />
			) : (
				<div className="ninjadash-authentication-content">
					<Form name="login" form={form}
					      onFinish={submit}
					      layout="vertical"
					      id={'form-add-account'}
					      key={'form-add-account'}
					>

						{/*<Form.Item hidden>
                        <Form.Item
                            hidden="true"
                            name="keyName"
                            initialValue={companyKey}
                        >
                            <Input/>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item hidden>
                        <Form.Item
                            hidden="true"
                            name="auth_object"
                            initialValue={state.selectedAuth}
                        >
                            <Input/>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item hidden>
                        <Form.Item
                            hidden="true"
                            name="account_object"
                            initialValue={state.selectedAccount}
                        >
                            <Input/>
                        </Form.Item>
                    </Form.Item>*/}

						<Form.Item hidden>
							<Form.Item
								hidden="true"
								name="currency"
								initialValue={'JYP'}
							>
								<Input />
							</Form.Item>
						</Form.Item>

						<Form.Item hidden>
							<Form.Item
								hidden="true"
								name="utc"
								initialValue={'-05:00'}
							>
								<Input />
							</Form.Item>
						</Form.Item>

						<Form.Item
							name="alias"
							rules={[{
								message: 'Please input your alias!',
								required: true,
							}]}
							label="Alias"
						>
							<Input placeholder="Place your alias here" />
						</Form.Item>

						<Form.Item
							name="name"
							rules={[{
								message: 'Please input your name!',
								required: true,
							}]}
							label="Name"
						>
							<Input placeholder="Place your name here" />
						</Form.Item>

						{/*<Form.Item
                        name="currency"
                        rules={[{
                            message: 'Please input your currency!',
                            required: true
                        }]}
                        label="Currency"
                    >
                        <Input placeholder="Place your currency here"/>
                    </Form.Item>*/}
					</Form>
					<Stats step={3} {...props} />
				</div>
			)
		);
	};

	return (
		<StepWizard
			initialStep={1}
			instance={setInstance}
		>
			<First stepName="FirstStep" />
			<Second stepName="SecondStep" />
			<Last stepName="TheEnd!" />
		</StepWizard>
	);
};


export default AccountMenuModal;
