import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar, Switch } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { InfoWraper, UserDropDwon } from './auth-info-style';
import { changeLayoutMode } from '../../../redux/themeLayout/actionCreator';
import Settings from './settings';
import { logOut } from '../../../redux/authentication/actionCreator';
import { Popover } from '../../popup/popup';

const AuthInfo = React.memo(() => {
	const dispatch = useDispatch();

	const { mode } = useSelector((state) => {
		return {
			mode: state.ChangeLayoutMode.mode,
		};
	});

	const [state, setState] = useState({
		colorMode: mode === 'lightMode',
	});
	const navigate = useNavigate();

	const SignOut = (e) => {
		e.preventDefault();

		dispatch(logOut(() => navigate('/')));
	};

	const { isLoading, data } = useSelector((state) => {
		return {
			isLoading: state.topMenuGetUser.loading,
			data: state.topMenuGetUser.data,
		};
	});

	const userContent = (
		<UserDropDwon>
			<div className="user-dropdwon">
				<figure className="user-dropdwon__info">
					<img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
				</figure>
				<ul className="user-dropdwon__links">
					<li>
						<Link to="#">
							<UilUser /> Profile
						</Link>
					</li>
					<li>
						<Link to="#">
							<UilSetting /> Settings
						</Link>
					</li>
				</ul>
				<Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
					<UilSignout /> Sign Out
				</Link>
			</div>
		</UserDropDwon>
	);

	return (
		<InfoWraper>
			<Switch onChange={() => {
				if (mode === 'lightMode') {
					document.body.classList.add('dark');
					dispatch(changeLayoutMode('darkMode'));
				} else {
					document.body.classList.remove('dark');
					dispatch(changeLayoutMode('lightMode'));
				}
			}}
			        checked={mode === 'lightMode'}
			        checkedChildren={'Light'}
			        unCheckedChildren={'Dark'}
			        defaultChecked
			/>
			<Settings />
			<div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
				<Popover placement="bottomRight" content={userContent} action="click">
					<Link to="#" className="ninjadash-nav-action-link">
						<Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" alt={
							isLoading ? 'Loading...' : data?.name
						} />
						<span className="ninjadash-nav-actions__author--name">{
							isLoading ? 'Loading...' : data?.name
						}</span>
						<UilAngleDown />
					</Link>
				</Popover>
			</div>
		</InfoWraper>
	);
});

export default AuthInfo;

