const actions = {
	TOP_MENU_READ_BEGIN: 'TODO_READ_BEGIN',
	TOP_MENU_READ_SUCCESS: 'TODO_READ_SUCCESS',
	TOP_MENU_READ_ERR: 'TODO_READ_ERR',

	topMenuReadBegin: () => {
		return {
			type: actions.TOP_MENU_READ_BEGIN,
		};
	},

	topMenuReadSuccess: (data) => {
		return {
			type: actions.TOP_MENU_READ_SUCCESS,
			data,
		};
	},

	topMenuReadErr: (err) => {
		return {
			type: actions.TOP_MENU_READ_ERR,
			err,
		};
	},

};

export default actions;
