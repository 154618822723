import getSymbolFromCurrency from "currency-symbol-map";
import {IconButton} from "@mui/material";
import {SwEdit} from "../utilities/icons";
import React, {useEffect, useState} from "react";
import {campaignsListBudgetUpdate} from "../../redux/campaigns/actionCreator";
import {useSelector} from "react-redux";
import useSharedFunctions from "./SharedFunctions";

const CampaignBudgetRender = ({ value, data, editableRowId, setEditableRowId }) => {

    const { updateError, success, updateLoading } = useSelector((state) => {
        return {
            updateError: state.campaignsReducer.updateError,
            success: state.campaignsReducer.success,
            updateLoading: state.campaignsReducer.updateLoading,
        };
    });

    const { handleUpdateBudget } = useSharedFunctions();

    useEffect(() => {
        if (updateError) {
            setInnerValueCache(innerValue);
        }
        if (success) {
            setInnerValue(innerValueCache);
        }
    }, [updateError, success]);

    const [innerValue, setInnerValue] = useState(value);
    const [innerValueCache, setInnerValueCache] = useState(value);

    useEffect(() => {
        setInnerValue(value);
        setInnerValueCache(value);
    }, [value]);

    useEffect(() => {
        if (innerValue !== innerValueCache) {
            handleUpdateBudget({ values: innerValueCache, campaign: data});
        }
    }, [innerValueCache]);


    if (editableRowId === data.id) {
        return (
            <input
                type="number"
                defaultValue={value}
                style={{ width: '100%' }}
                onBlur={(e) => {
                    setInnerValueCache(e.target.value);
                    setEditableRowId(null); // Stop editing when focus is lost
                }}
            />
        );
    } else {
        return (
            <>
                {parseFloat(innerValueCache)} {getSymbolFromCurrency(data.currency)}
                <IconButton
                    size="small"
                    color="primary"
                    aria-label="edit"
                    onClick={() => setEditableRowId(data.id)}
                    className={'component-edit-button'}
                    style={{
                        marginLeft: 5,
                    }}
                    disabled={updateLoading}
                >
                    <SwEdit
                        props={{
                            width: 20,
                            height: 20,
                        }} />
                </IconButton>
            </>
        );
    }
}

export default CampaignBudgetRender;