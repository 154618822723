import actions from './actions';

const {
	SUB_CAMPAIGNS_READ_BEGIN,
	SUB_CAMPAIGNS_READ_SUCCESS,
	SUB_CAMPAIGNS_READ_ERR,
} = actions;

const initialState = {
	data: [],
	loading: true,
	error: null,
	message: '',
};

const subCampaignsReducer = (state = initialState, action) => {
	const { type, data, err } = action;
	switch (type) {
		case SUB_CAMPAIGNS_READ_BEGIN:
			return {
				...state,
				loading: true,
			};

		case SUB_CAMPAIGNS_READ_SUCCESS:
			return {
				...state,
				data,
				error: false,
				loading: false,
			};

		case SUB_CAMPAIGNS_READ_ERR:
			return {
				...state,
				error: err,
				loading: false,
				message: 'Campaigns read failed',
			};


		default:
			return state;
	}
};

export { subCampaignsReducer };
