const actions = {
	INTEGRATION_ACCOUNT_CREATE_BEGIN: 'INTEGRATION_ACCOUNT_CREATE_BEGIN',
	INTEGRATION_ACCOUNT_CREATE_SUCCESS: 'INTEGRATION_ACCOUNT_CREATE_SUCCESS',
	INTEGRATION_ACCOUNT_CREATE_ERR: 'INTEGRATION_ACCOUNT_CREATE_ERR',

	INTEGRATION_ACCOUNT_READ_BEGIN: 'INTEGRATION_ACCOUNT_READ_BEGIN',
	INTEGRATION_ACCOUNT_READ_SUCCESS: 'INTEGRATION_ACCOUNT_READ_SUCCESS',
	INTEGRATION_ACCOUNT_READ_ERR: 'INTEGRATION_ACCOUNT_READ_ERR',

	INTEGRATION_ACCOUNT_UPDATE_BEGIN: 'INTEGRATION_ACCOUNT_UPDATE_BEGIN',
	INTEGRATION_ACCOUNT_UPDATE_SUCCESS: 'INTEGRATION_ACCOUNT_UPDATE_SUCCESS',
	INTEGRATION_ACCOUNT_UPDATE_ERR: 'INTEGRATION_ACCOUNT_UPDATE_ERR',


	integrationAccountCreateBegin: () => {
		return {
			type: actions.INTEGRATION_ACCOUNT_CREATE_BEGIN,
		};
	},

	integrationAccountCreateSuccess: (data) => {
		return {
			type: actions.INTEGRATION_ACCOUNT_CREATE_SUCCESS,
			data,
		};
	},

	integrationAccountCreateErr: (err) => {
		return {
			type: actions.INTEGRATION_ACCOUNT_CREATE_ERR,
			err,
		};
	},

	integrationAccountReadBegin: () => {
		return {
			type: actions.INTEGRATION_ACCOUNT_READ_BEGIN,
		};
	},

	integrationAccountReadSuccess: (data) => {
		return {
			type: actions.INTEGRATION_ACCOUNT_READ_SUCCESS,
			data,
		};
	},

	integrationAccountReadErr: (err) => {
		return {
			type: actions.INTEGRATION_ACCOUNT_READ_ERR,
			err,
		};
	},

	integrationAccountUpdateBegin: () => {
		return {
			type: actions.INTEGRATION_ACCOUNT_UPDATE_BEGIN,
		};
	},

	integrationAccountUpdateSuccess: (data) => {
		return {
			type: actions.INTEGRATION_ACCOUNT_UPDATE_SUCCESS,
			data,
		};
	},

	integrationAccountUpdateErr: (err) => {
		return {
			type: actions.INTEGRATION_ACCOUNT_UPDATE_ERR,
			err,
		};
	},
};

export default actions;
