import Styled from "styled-components";

const StyledTableTabPanel = Styled.div`
    padding: 5px;
    background-color: ${({ theme }) => theme[theme.mainContent]['dark-background']};
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
`;

export { StyledTableTabPanel };