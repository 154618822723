const themeVariable = {
	lightMode: {
		'main-background': '#F8F9FB',
		'main-background-light': '#F8F9FB',
		'dark-background': '#EFF0F3',
		'darker-background': '#4d5381',
		'darker2-background': '#202444',
		'white-background': '#FFFFFF',
		'general-background': '#F4F5F7',
		'brand-background': '#FFFFFF',
		'status-background': '#F8F9FB',
		'primary-background': '#8231D3',
		'primary-transparent': '#F2EAFB',
		'light-background': '#F8F9FB',
		'darker1-background': '#d9dce3',
		'light-border': '#58595b',
		'gray-border': '#F1F2F6',
		'white-text': '#ffffff',
		'gray-text': '#404040',
		'gray-lighter-text': '#404040',
		'gray-light-text': '#747474',
		'dark-text': '#0A0A0A',
		'light-text': '#747474',
		'extra-light-text': '#A0A0A0',
		'light-gray-text': '#404040',
		'extra-light': '#A0A0A0',
		'border-color-default': '#F1F2F6',
		'border-color-tab': '#f0f0f0',
		'border-color-checkbox': '#C6D0DC',
		'border-color-secondary': '#E3E6EF',
		'border-color-deep': '#C6D0DC',
		'menu-icon-color': '#A0A0A0',
		'menu-active': '#8231D3',
		'menu-active-bg': '#F2EAFB',
		'scroll-bg': '#E3E6EF',
		'chat-bg': '#F4F5F7',
		'chat-reply-bg': '#404040',
		'chat-reply-text': '#ffffff',
		'input-bg': '#ffffff',
		'input-text': '#747474',
		'table-bg-even': '#F8F9FB',
		'table-bg-odd': '#ffffff',
		'table-bg-hover': '#e7e2ea',
		'table-bg-selected': '#F2EAFB',
	},
	darkMode: {
		'main-background': '#010413',
		'main-background-light': '#282B37',
		'dark-background': '#282B37',
		'darker-background': '#1B1E2B',
		'darker2-background': '#1B1E2B',
		'light-background': '#282B37',
		'darker1-background': '#181e2f',
		'status-background': '#282B37' /* 6% */,
		'primary-background': '#282B37',
		'primary-transparent': '#323541',
		'white-background': '#1B1E2B',
		'general-background': '#323541',
		'brand-background': '#323541',
		'light-border': '#282B37',
		'gray-border': '#363c4b',
		'white-text': '#1B1E2B',
		'gray-text': '#A4A5AA' /* 60% */,
		'gray-lighter-text': '#E1E1E3' /* 87% of #fff */,
		'gray-light-text': '#A4A5AA',
		'dark-text': '#E1E1E3' /* 87% */,
		'light-text': '#4D4F5A' /* 30% */,
		'extra-light-text': '#A4A6AB',
		'light-gray-text': '#E1E1E3',
		'extra-light': '#ADAEB3',
		'border-color-default': '#323541',
		'border-color-tab': '#323541',
		'border-color-checkbox': '#A4A6AB',
		'border-color-secondary': '#494B55',
		'border-color-deep': '#070A19',
		'menu-icon-color': '#A4A5AA',
		'menu-active': '#ffffff',
		'menu-active-bg': '#282B37',
		'scroll-bg': '#565A65' /* 30% */,
		'chat-bg': '#282B37',
		'chat-reply-bg': '#282B37',
		'chat-reply-text': '#A4A5AA',
		'input-bg': '#282B37',
		'input-text': '#A4A5AA',
		'table-bg-even': '#282B37',
		'table-bg-odd': '#1B1E2B',
		'table-bg-hover': '#404350',
		'table-bg-selected': '#404665',
	},
};

export default themeVariable;
