const actions = {
	CAMPAIGN_TABLE_CHANGE_FILTER: 'CAMPAIGN_TABLE_CHANGE_FILTER',

	campaignTableChangeFilter: () => {
		return {
			type: actions.CAMPAIGN_TABLE_CHANGE_FILTER,
		};
	},
};

export default actions;
