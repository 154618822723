import React, {useEffect} from 'react';
import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n/config';
import reportWebVitals from './reportWebVitals';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

/*Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_ENVIRONMENT,
});*/

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		Sentry.replayIntegration()
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/api\.arbitrax\.co\/api\/v1/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
