import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import dataTable from './data-filter/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { readNotificationReducer } from './notification/reducers';
import Profile from './profile/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { denemeApiReducer } from './denemeApi/reducers';
import TopMenuGetUserReducer from './topMenuGetUser/reducers';
import integrationReducer from './integrations/reducers';
import { campaignsReducer } from './campaigns/reducers';
import campaignsTabReducer from './campaignsTabs/reducers';
import { subCampaignsReducer } from './subCampaigns/reducers';
import integrationAccountsReducer from "./integrationAccounts/reducers";
import campaignTableReducer from "./campaignTable/reducers";


const rootReducers = combineReducers({
	headerSearchData: headerSearchReducer,
	notification: readNotificationReducer,
	denemeApi: denemeApiReducer,
	auth: authReducer,
	ChangeLayoutMode,
	Profile,
	dataTable,
	campaignsReducer: campaignsReducer,
	campaignTableReducer,
	campaignsTabReducer,
	subCampaignsReducer,
	topMenuGetUser: TopMenuGetUserReducer,
	integrations: integrationReducer,
	integrationAccounts: integrationAccountsReducer
});

export default rootReducers;
