import React from "react";

const PlatformTargetRender = ({ value }) => {
    if (Array.isArray(value)){
        var icons = [];
        for (let i = 0; i < value.length; i++) {
            let iconName = value[i].toLowerCase().trim();
            if (iconName === "mobile"){
                iconName = "phon";
            }
            icons.push(
                <img
                    key={value[i]}
                    src={require(`../../static/img/campaign-platform/${iconName}.svg`)}
                    alt={value[i]}
                    style={{ width: '20px', height: '20px', marginRight: '5px' }}
                />
            )
        }
        return icons;
    }
    return value;
}

export default PlatformTargetRender;