import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
	subCampaignsReadBegin,
	subCampaignsReadSuccess,
	subCampaignsReadErr,
} = actions;

function flattenObject(obj, prefix = '') {
	return Object.keys(obj).reduce((acc, k) => {
		const pre = prefix.length ? prefix + '_' : '';
		if (pre === 'campaign_'){
			return acc;
		}
		else if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
			Object.assign(acc, flattenObject(obj[k], pre + k));
		} else {
			acc[pre + k] = obj[k];
		}
		return acc;
	}, {});
}

const subCampaignsListRead = ({company, type, campaignId}) => {
	return async (dispatch) => {
		try {
			dispatch(subCampaignsReadBegin());

			const response = await DataService.get(`/campaigns/${company}/breakdown/${type}`, {campaign_id : campaignId});

			if (response.status === 200) {
				let prettyData;
				if (type === 'publisher'){
					if ('results' in response.data) {
						prettyData = response.data['results'].map((item) => {
							return flattenObject(item);
						});
					} else {
						prettyData = response.data.map((item) => {
							return flattenObject(item);
						});
					}
				}else{
					prettyData= response.data.map((item) => {
						return flattenObject(item);
					});
				}
				dispatch(subCampaignsReadSuccess(prettyData));
			} else {
				dispatch(subCampaignsReadErr(response.status));
			}
		} catch (err) {
			dispatch(subCampaignsReadErr(err));
		}
	};
};

export {
	subCampaignsListRead,
};
