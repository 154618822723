import React, {useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {useSelector} from "react-redux";
import useSharedFunctions from "./SharedFunctions";

const NumericStepperComponent = ({ initialValue, campaign, type }) => {

    const { updateError, success, updateLoading } = useSelector((state) => {
        return {
            updateError: state.campaignsReducer.updateError,
            success: state.campaignsReducer.success,
            updateLoading: state.campaignsReducer.updateLoading,
        };
    });

    const { handleUpdateBid } = useSharedFunctions();

    const [innerValue, setInnerValue] = useState(
        parseFloat(initialValue)
    );
    const [innerValueCache, setInnerValueCache] = useState(
        parseFloat(initialValue)
    );

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            if (innerValue !== innerValueCache) {
                await handleUpdateBid({ values: innerValueCache, campaign: campaign});
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [innerValueCache]);

    useEffect(() => {
        if (updateError) {
            setInnerValueCache(innerValue);
        }
        if (success) {
            setInnerValue(innerValueCache);
        }
    }, [updateError, success]);

    useEffect(() => {
        setInnerValueCache(parseFloat(initialValue));
        setInnerValue(parseFloat(initialValue));
    }, [initialValue]);

    return (
        <div className={'antd-campaign-table-bid'}>
            <IconButton
                size="small"
                color="primary"
                aria-label="minus"
                disabled={innerValueCache <= 0.01 || updateLoading}
                onClick={() => {
                    const total = innerValueCache - 0.01;
                    setInnerValueCache(
                        parseFloat(total.toFixed(4))
                    );

                }}
            >
                <RemoveIcon />
            </IconButton>
            <span className={'antd-campaign-table-bid-value'}>{parseFloat(innerValueCache).toFixed(4)}</span>
            <IconButton
                size="small"
                color="primary"
                aria-label="plus"
                disabled={updateLoading}
                onClick={() => {
                    const total = innerValueCache + 0.01;
                    setInnerValueCache(
                        parseFloat(total.toFixed(4))
                    );
                }}
            >
                <AddIcon />
            </IconButton>
        </div>
    );
};

export default NumericStepperComponent;