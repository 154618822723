import PropTypes from 'prop-types';
import React from 'react';
import { CardFrame } from './style';
import Heading from '../../heading/heading';

function Cards(props) {
	const {
		title,
		children,
		more,
		size,
		headless,
		caption,
		isbutton,
		bodyStyle,
		headStyle,
		border,
		bodypadding,
		className,
	} = props;
	return (
		<>
			{!headless ? (
				<CardFrame
					size={size}
					title={title}
					bodyStyle={bodyStyle && bodyStyle}
					headStyle={headStyle && headStyle}
					bordered={border}
					className={className}
					bodypadding={bodypadding && bodypadding}
					extra={
						<>
							{more && more}

							{isbutton && isbutton}
						</>
					}
					style={{ width: '100%' }}
				>
					{children}
				</CardFrame>
			) : (
				<CardFrame
					bodypadding={bodypadding && bodypadding}
					bodyStyle={bodyStyle && bodyStyle}
					size={size}
					style={{ width: '100%' }}
					bordered={border}
					className={className}
				>
					{title && <Heading as="h4">{title}</Heading>}
					{caption && <p>{caption}</p>}
					{children}
				</CardFrame>
			)}
		</>
	);
}

Cards.defaultProps = {
	border: false,
};

Cards.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
	size: PropTypes.string,
	more: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
	bodyStyle: PropTypes.object,
	headStyle: PropTypes.object,
	isbutton: PropTypes.node,
	headless: PropTypes.bool,
	border: PropTypes.bool,
	caption: PropTypes.string,
	bodypadding: PropTypes.string,
	className: PropTypes.string, children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

export { Cards };
