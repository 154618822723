import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
	campaignsReadBegin,
	campaignsReadSuccess,
	campaignsReadErr,

	campaignsCreateBegin,
	campaignsCreateSuccess,
	campaignsCreateErr,

	campaignsUpdateBegin,
	campaignsUpdateSuccess,
	campaignsUpdateErr,

	campaignsDeleteBegin,
	campaignsDeleteSuccess,
	campaignsDeleteErr,

	campaignsSetTargetCountry,
	campaignsSetTargetPlatform,
	campaignsSetTargetOS,

	campaignsSetBrandArray
} = actions;

const campaignsListRead = () => {
	return async (dispatch) => {
		try {
			dispatch(campaignsReadBegin());

			const response = await DataService.get('/campaigns/list');

			if (response.status === 200) {
				const totalArray = [];
				var brandNameCount = {};

				for (var key in response.data) {
					if (Array.isArray(response.data[key])) {
						brandNameCount[key] = response.data[key].length;
						const data = response.data[key].map((item) => {
							item.id = item.id + '_' + key;
							for (var key2 in item.details) {
								for (var rest in item.details[key2]) {
									item[key2 + "_" + rest] = item.details[key2][rest];
								}
							}
							item.campaign_type = key;
							delete item.details;
							return item;
						});
						totalArray.push(data);
					}
					else {
						brandNameCount[key] = 0;
						const data = []
						for (var subKey in response.data[key]) {
							brandNameCount[key] += response.data[key][subKey].length;
							const subData = response.data[key][subKey].map((item) => {
								item.id = item.id + '_' + key;
								item.subKey = subKey;
								for (var key2 in item.details) {
									for (var rest in item.details[key2]) {
										item[key2 + "_" + rest] = item.details[key2][rest];
									}
								}
								item.campaign_type = key;
								item.campaign_subtype = subKey;
								item.name = item.subKey + " - " + item.name;
								delete item.details;
								return item;
							});
							/*data.push({
								id: key,
								name: subKey,
								nodes: subData
							});*/
							data.push(...subData);
						}
						totalArray.push(data);
					}
				}
				dispatch(campaignsSetBrandArray(brandNameCount));
				dispatch(campaignsReadSuccess(totalArray.flat()));
			} else {
				dispatch(campaignsReadErr(response.status));
			}
		} catch (err) {
			dispatch(campaignsReadErr(err));
		}
	};
};

const campaignsListBidUpdate = (data, campaign) => {
	return async (dispatch) => {
		try {
			var response;
			dispatch(campaignsUpdateBegin());
			if (campaign.campaign_type === 'taboola'){
				if (campaign.type === 'publisher'){
					response = await DataService.post('campaigns/taboola/publisher/bid/update', {campaign_id: campaign.id, publisher_name: data.value});
					if (response.status === 200) {
						if (response.data.status === 'success'){
							dispatch(campaignsUpdateSuccess());
						}
						else{
							dispatch(campaignsUpdateErr(response.data.message));
						}
					} else {
						dispatch(campaignsUpdateErr(response.status));
					}
				}
				else{
					response = await DataService.post('campaigns/tabolaa/bid/update', {adgroup_id: campaign.id, bid: data});
					if (response.status === 200) {
						if (response.data.status === 'success'){
							dispatch(campaignsUpdateSuccess());
						}
						else{
							dispatch(campaignsUpdateErr(response.data.message));
						}
					} else {
						dispatch(campaignsUpdateErr(response.status));
					}
				}
			}
			else if (campaign.campaign_type === 'tiktok'){
				response = await DataService.post('campaigns/tiktok/adgroup/bid/update', {adgroup_id: campaign.id, bid_price: data});
				if (response.status === 200) {
					if (response.data.status){
						dispatch(campaignsUpdateSuccess());
					}
					else{
						dispatch(campaignsUpdateErr(response.data.message));
					}
				} else {
					dispatch(campaignsUpdateErr(response.status));
				}
			}
		} catch (err) {
			dispatch(campaignsUpdateErr(err));
		}
	};
};

const campaignsListBudgetUpdate = (data, campaign) => {
	return async (dispatch) => {
		try {
			let response;
			dispatch(campaignsUpdateBegin());
			if (campaign.campaign_type === 'tabolaa'){
				response = await DataService.post('campaigns/tabolaa/budget/update', {adgroup_id: campaign.id, budget: data});
				if (response.status === 200) {
					if (response.data.status === 'success'){
						dispatch(campaignsUpdateSuccess());
					}
					else{
						dispatch(campaignsUpdateErr(response.data.message));
					}
				} else {
					dispatch(campaignsUpdateErr(response.status));
				}
			}
			else if (campaign.campaign_type === 'tiktok'){
				const clearId = campaign.id.split('_');
				response = await DataService.post('campaigns/tiktok/adgroup/budget/update', {adgroup_id: clearId[0], budget: data});
				if (response.status === 200) {
					if (response.data.status){
						dispatch(campaignsUpdateSuccess());
					}
					else{
						dispatch(campaignsUpdateErr(response.data.message));
					}
				} else {
					dispatch(campaignsUpdateErr(response.status));
				}
			}
		} catch (err) {
			dispatch(campaignsUpdateErr(err));
		}
	};
}

const campaignsListAvailableUpdate = (data, campaign) => {
	return async (dispatch) => {
		try {
			dispatch(campaignsUpdateBegin());
			if (campaign.campaign_type === 'taboola'){
				if (campaign.type === 'creative'){
					const response = await DataService.post('campaigns/taboola/creative/status', {creative_id: campaign.id, status: data ? "true" : "false"});
					if (response.status === 200) {
						if (response.data.status === 'success'){
							dispatch(campaignsUpdateSuccess());
						}
						else{
							dispatch(campaignsUpdateErr(response.data.message));
						}
					} else {
						dispatch(campaignsUpdateErr(response.status));
					}
				}
				else if (campaign.type === 'publisher'){
					const response = await DataService.post('campaigns/taboola/publisher/block/status', {publisher_id: campaign.id, status: data ? "true" : "false", publisher_name: campaign.name});
					if (response.status === 200) {
						if (response.data.status === 'success'){
							dispatch(campaignsUpdateSuccess());
						}
						else{
							dispatch(campaignsUpdateErr(response.data.message));
						}
					} else {
						dispatch(campaignsUpdateErr(response.status));
					}
				}
			}
			else if (campaign.campaign_type === 'tiktok'){
				const response = await DataService.post('campaigns/tiktok/campaign/status', {adgroup_id: campaign.id, status: data ? "true" : "false"});
				if (response.status === 200) {
					if (response.data.status){
						dispatch(campaignsUpdateSuccess());
					}
					else{
						dispatch(campaignsUpdateErr(response.data.message));
					}
				} else {
					dispatch(campaignsUpdateErr(response.status));
				}
			}

		} catch (err) {
			dispatch(campaignsUpdateErr(err));
		}
	};
};

export {
	campaignsListRead,
	campaignsListBidUpdate,
	campaignsListAvailableUpdate,
	campaignsListBudgetUpdate
};
