import { themeColor } from './theme/themeVariables';

const config = {
	topMenu: false,
	rtl: false,
	mainTemplate: 'lightMode',
	themeColor,
};

export default config;
