import actions from './actions';

const {
	CAMPAIGNS_READ_BEGIN,
	CAMPAIGNS_READ_SUCCESS,
	CAMPAIGNS_READ_ERR,

	CAMPAIGNS_CREATE_BEGIN,
	CAMPAIGNS_CREATE_SUCCESS,
	CAMPAIGNS_CREATE_ERR,

	CAMPAIGNS_UPDATE_BEGIN,
	CAMPAIGNS_UPDATE_SUCCESS,
	CAMPAIGNS_UPDATE_ERR,

	CAMPAIGNS_DELETE_BEGIN,
	CAMPAIGNS_DELETE_SUCCESS,
	CAMPAIGNS_DELETE_ERR,

	CAMPAIGNS_SET_BRAND_ARRAY
} = actions;

const initialState = {
	data: [],
	loading: true,
	updateLoading: false,
	success: false,
	error: null,
	updateError: null,
	message: null,
	campaignsBrands: [],
	campaignCountryTarget: [],
	campaignPlatformTarget: [],
	campaignOSTarget: [],
	lastFetchTime: null,
};

const campaignsReducer = (state = initialState, action) => {
	const { type, data, err } = action;
	switch (type) {
		case CAMPAIGNS_READ_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_READ_SUCCESS:
			return {
				...state,
				data,
				lastFetchTime: new Date(),
				error: false,
				loading: false,
			};

		case CAMPAIGNS_READ_ERR:
			return {
				...state,
				error: err,
				loading: false,
				message: 'Campaigns read failed',
			};

		case CAMPAIGNS_CREATE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_CREATE_SUCCESS:
			return {
				...state,
				data,
				error: false,
				loading: false,
				message: 'Campaign created successfully',
			};

		case CAMPAIGNS_CREATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
				message: 'Campaign creation failed',
			};

		case CAMPAIGNS_UPDATE_BEGIN:
			return {
				...state,
				updateLoading: true,
				success: false,
				updateError: null,
			};

		case CAMPAIGNS_UPDATE_SUCCESS:
			return {
				...state,
				success: true,
				updateLoading: false,
				updateError: false,
				message: 'Campaign updated successfully',
			};

		case CAMPAIGNS_UPDATE_ERR:
			return {
				...state,
				updateLoading: false,
				success: false,
				updateError: err,
				message: 'Campaign update failed',
			};

		case CAMPAIGNS_DELETE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_DELETE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
				message: 'Campaign deleted successfully',
			};

		case CAMPAIGNS_DELETE_ERR:
			return {
				...state,
				error: err,
				loading: false,
				success: false,
				message: 'Campaign delete failed',
			};

		case CAMPAIGNS_SET_BRAND_ARRAY:
			return {
				...state,
				campaignsBrands: data,
			};


		default:
			return state;
	}
};

export { campaignsReducer };
