import actions from './actions';

const {
	DENEME_API_READ_BEGIN,
	DENEME_API_READ_SUCCESS,
	DENEME_API_READ_ERR,

	DENEME_API_CREATE_BEGIN,
	DENEME_API_CREATE_SUCCESS,
	DENEME_API_CREATE_ERR,

	DENEME_API_UPDATE_BEGIN,
	DENEME_API_UPDATE_SUCCESS,
	DENEME_API_UPDATE_ERR,

	DENEME_API_DELETE_BEGIN,
	DENEME_API_DELETE_SUCCESS,
	DENEME_API_DELETE_ERR,
} = actions;

const initialState = {
	data: [],
	loading: false,
	error: null,
	success: false,
};

const denemeApiReducer = (state = initialState, action) => {
	const { type, data, err } = action;
	switch (type) {
		case DENEME_API_READ_BEGIN:
			return {
				...state,
				loading: true,
			};

		case DENEME_API_READ_SUCCESS:
			return {
				...state,
				data,
				error: false,
				loading: false,
			};

		case DENEME_API_READ_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case DENEME_API_CREATE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case DENEME_API_CREATE_SUCCESS:
			return {
				...state,
				data,
				error: false,
				loading: false,
			};

		case DENEME_API_CREATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case DENEME_API_UPDATE_BEGIN:
			return {
				...state,
				loading: true,
				success: false,
			};

		case DENEME_API_UPDATE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
			};

		case DENEME_API_UPDATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
				success: false,
			};

		case DENEME_API_DELETE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case DENEME_API_DELETE_SUCCESS:
			return {
				...state,
				success: true,
				error: false,
				loading: false,
			};

		case DENEME_API_DELETE_ERR:
			return {
				...state,
				error: err,
				loading: false,
				success: false,
			};


		default:
			return state;
	}
};

export { denemeApiReducer };
