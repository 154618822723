import { UilBriefcase, UilCreateDashboard } from '@iconscout/react-unicons';
import { Form, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { Modal } from '../components/modals/antd-modals';
import { openNotificationWithIcon } from '../container/ui-elements/Notification';
import AccountMenuModal from './AccountMenuModal';
import AuthMenuModal from './AuthMenuModal';
import UilShareAlt from '@iconscout/react-unicons/icons/uil-share-alt';
import { MenuStyleWrapper } from './Style';

function MenuItems({ toggleCollapsed }) {
	const { t } = useTranslation();
	const [state, setState] = useState(
		{
			visible: false,
			modalTitle: 'Basic Modal',
			modalType: 'auth', // auth, account
			selectedAuth: null,
			selectedAccount: null,
			SW: null,
		});
	const [selectedIntegration, setSelectedIntegration] = useState(null);
	const [form1, form2] = Form.useForm();
	const path = '/admin';
	const pathName = window.location.pathname;
	const pathArray = pathName && pathName !== '/' ? pathName.split(path) : [];
	const mainPath = pathArray.length > 1 ? pathArray[1] : '';
	const mainPathSplit = mainPath.split('/');

	const { error, success } = useSelector((rootState) => {
		return {
			error: rootState.integrations.error,
			success: rootState.integrations.successfull,
		};
	});
	const setInstance = SW => {
		if (!state.SW) {
			setState(prevState => {
				return {
					...prevState,
					SW: SW,
				};
			});
		}
	};

	useEffect(() => {
		if (state.modalType == 'account') {
			if (state.SW) {
				state.SW.firstStep();
			}
		}
	}, [state.SW, state.visible]);

	function resetForms() {
		if (form1)
			form1.resetFields();
		if (form2)
			form2.resetFields();
	}

	React.useEffect(() => {
		if (error) {
			openNotificationWithIcon('error', 'Error', error);
		}
		if (success) {
			resetForms();
			handleCancel();
			openNotificationWithIcon('success', 'Success', 'Operation is successfully');
		}
	}, [error, success]);


	function getItem(label, key, icon, children, type) {
		return {
			key,
			icon,
			children,
			label,
			type,
		};
	}

	const onOpenChange = async (keys) => {
		const latestKey = keys[keys.length - 1];
		const parentKey = latestKey?.split('-')[0];

		if (parentKey === 'integrations' && latestKey.split('-').length > 1) {
			setSelectedIntegration(latestKey.split('-')[1]);
		}
	};

	const handleCancel = () => {
		setState(prevState => {
			return {
				...prevState,
				visible: false,
				selectedAuth: null,
				selectedAccount: null,
			};
		});
		resetForms();

	};

	const items = [
		getItem(<NavLink onClick={toggleCollapsed} to={path}>
				{t('dashboard')}
			</NavLink>,
			'dashboard',
			<UilCreateDashboard />,
			null
		),

		getItem(<NavLink onClick={toggleCollapsed} to={`${path}/campaigns`}>
			{t('campaigns')}
		</NavLink>,
			'campaigns', <UilBriefcase />, null),

		getItem(undefined, undefined, undefined, undefined, 'divider'),

		getItem(<NavLink onClick={toggleCollapsed} to={`${path}/integrations`}>
			{t('Integrations')}
		</NavLink>, 'integrations', <UilShareAlt />, null),
	];

	return (
		<>
			<Modal
				type={'primary'}
				color={'primary'}
				title={state.modalTitle}
				open={state.visible}
				footer={null}
				onCancel={handleCancel}
			>
				{
					state.modalType === 'auth' ? (
						<AuthMenuModal companyKey={selectedIntegration} form={form1} />
					) : (
						<AccountMenuModal
							state={state}
							setState={setState}
							setInstance={setInstance}
							companyKey={selectedIntegration}
							form={form2} />
					)
				}
			</Modal>
			<MenuStyleWrapper>
				<Menu
					onOpenChange={onOpenChange}
					mode={'inline'}
					defaultSelectedKeys={
						[
							`${
								mainPathSplit.length > 2
									? mainPathSplit[2] === 'info'
										? `${mainPathSplit[1]}`
										: `${mainPathSplit[2]}`
									: mainPathSplit[1]
							}`,
						]
					}
					overflowedIndicator={<UilEllipsisV />}
					items={items}
				/>
			</MenuStyleWrapper>
		</>
	);
}

MenuItems.propTypes = {
	toggleCollapsed: propTypes.func,
};

export default MenuItems;
