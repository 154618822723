import actions from './actions';

const initialState = {
	data: null,
	loading: false,
	error: null,
};

const {
	TOP_MENU_READ_BEGIN,
	TOP_MENU_READ_SUCCESS,
	TOP_MENU_READ_ERR,
} = actions;

const TopMenuGetUserReducer = (state = initialState, action) => {
	const { type, data, err } = action;
	switch (type) {
		case TOP_MENU_READ_BEGIN:
			return {
				...state,
				loading: true,
			};
		case TOP_MENU_READ_SUCCESS:
			return {
				...state,
				data,
				loading: false,
			};
		case TOP_MENU_READ_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};
		default:
			return state;
	}
};

export default TopMenuGetUserReducer;
