import PropTypes, { object } from 'prop-types';
import React from 'react';
import { ModalStyled } from './styled';
import { Button } from '../buttons/buttons';

function Modal(props) {
	const { onCancel, className, onOk, open, title, type, color, footer, width, children } = props;

	return (
		<ModalStyled
			title={title}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
			type={color ? type : false}
			width={width}
			className={className}
			footer={footer}
		>
			{children}
		</ModalStyled>
	);
}

Modal.defaultProps = {
	className: 'atbd-modal',
};

Modal.propTypes = {
	onCancel: PropTypes.func,
	onOk: PropTypes.func,
	visible: PropTypes.bool,
	title: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
	footer: PropTypes.arrayOf(object),
	width: PropTypes.number,
	color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const alertModal = ModalStyled;
export { Modal, alertModal };
