import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import moment from 'moment';
import axios from 'axios';

const {
	loginBegin,
	loginSuccess,
	loginErr,
	logoutBegin,
	logoutSuccess,
	logoutErr,
	updatePassBegin,
	updatePassSuccess,
	updatePassErr,
} = actions;

const login = (values, callback) => {
	return async (dispatch) => {
		dispatch(loginBegin());
		try {
			const response = await DataService.post('/auth/login', values);
			if (response.status !== 200) {
				dispatch(loginErr(response.data.errors));
			} else {
				const expiryDate = moment(response.data.expires_at).toDate();
				Cookies.set('access_token', response.data.access_token, { expires: expiryDate });
				Cookies.set('logedIn', true, { expires: expiryDate });
				dispatch(loginSuccess(true));
				callback();
			}
		} catch (err) {
			dispatch(loginErr(err));
		}
	};
};

const fbLogin = (callback) => {
	return async (dispatch) => {
		dispatch(loginBegin());
		try {
			Cookies.set('logedIn', true);
			dispatch(loginSuccess(true));
			callback();
		} catch (err) {
			dispatch(loginErr(err));
		}
	};
};

const register = (values, callback) => {
	return async (dispatch) => {
		dispatch(loginBegin());
		axios.post('https://api.arbitrax.co/api/v1/auth/register', values)
			.then(response => {
				if (response.status !== 200) {
					dispatch(loginErr('Registration failed!'));
				} else {
					const expiryDate = moment(response.data.expires_at).toDate();
					Cookies.set('access_token', response.data.access_token, { expires: expiryDate });
					Cookies.set('logedIn', true, { expires: expiryDate });
					dispatch(loginSuccess(true));
					callback();
				}
			})
			.catch(error => {
				alert(error.response.data.message);
				dispatch(loginErr(error));
			});

	};
};

const logOut = (callback) => {
	return async (dispatch) => {
		dispatch(logoutBegin());
		try {
			Cookies.remove('logedIn');
			Cookies.remove('access_token');
			dispatch(logoutSuccess(false));
			callback();
		} catch (err) {
			dispatch(logoutErr(err));
		}
	};
};

const updatePassword = (values) => {
	return async (dispatch) => {
		dispatch(updatePassBegin());
		try {
			const response = await DataService.put('/setPassword', values);
			if (response.status !== 200) {
				dispatch(updatePassErr(
					response.data.message
						? response.data.message
						: 'Password update failed!',
				));
			} else {
				dispatch(updatePassSuccess(true));
			}
		} catch (err) {
			dispatch(updatePassErr(err));
		}
	};
};

export { login, logOut, register, fbLogin, updatePassword };
