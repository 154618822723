import {openNotificationWithIcon} from "../../container/ui-elements/Notification";

const actions = {
	CAMPAIGNS_READ_BEGIN: 'CAMPAIGNS_READ_BEGIN',
	CAMPAIGNS_READ_SUCCESS: 'CAMPAIGNS_READ_SUCCESS',
	CAMPAIGNS_READ_ERR: 'CAMPAIGNS_READ_ERR',

	CAMPAIGNS_CREATE_BEGIN: 'CAMPAIGNS_CREATE_BEGIN',
	CAMPAIGNS_CREATE_SUCCESS: 'CAMPAIGNS_CREATE_SUCCESS',
	CAMPAIGNS_CREATE_ERR: 'CAMPAIGNS_CREATE_ERR',

	CAMPAIGNS_UPDATE_BEGIN: 'CAMPAIGNS_UPDATE_BEGIN',
	CAMPAIGNS_UPDATE_SUCCESS: 'CAMPAIGNS_UPDATE_SUCCESS',
	CAMPAIGNS_UPDATE_ERR: 'CAMPAIGNS_UPDATE_ERR',

	CAMPAIGNS_DELETE_BEGIN: 'CAMPAIGNS_DELETE_BEGIN',
	CAMPAIGNS_DELETE_SUCCESS: 'CAMPAIGNS_DELETE_SUCCESS',
	CAMPAIGNS_DELETE_ERR: 'CAMPAIGNS_DELETE_ERR',

	CAMPAIGNS_SET_TARGET_COUNTRY: 'CAMPAIGNS_SET_TARGET_COUNTRY',
	CAMPAIGNS_SET_TARGET_PLATFORM: 'CAMPAIGNS_SET_TARGET_PLATFORM',
	CAMPAIGNS_SET_TARGET_OS: 'CAMPAIGNS_SET_TARGET_OS',

	CAMPAIGNS_SET_BRAND_ARRAY: 'CAMPAIGNS_SET_BRAND_ARRAY',

	campaignsReadBegin: () => {
		return {
			type: actions.CAMPAIGNS_READ_BEGIN,
		};
	},

	campaignsReadSuccess: (data) => {
		return {
			type: actions.CAMPAIGNS_READ_SUCCESS,
			data,
		};
	},

	campaignsReadErr: (err) => {
		return {
			type: actions.CAMPAIGNS_READ_ERR,
			err,
		};
	},

	campaignsCreateBegin: () => {
		return {
			type: actions.CAMPAIGNS_CREATE_BEGIN,
		};
	},

	campaignsCreateSuccess: (data) => {
		return {
			type: actions.CAMPAIGNS_CREATE_SUCCESS,
			data,
		};
	},

	campaignsCreateErr: (err) => {
		return {
			type: actions.CAMPAIGNS_CREATE_ERR,
			err,
		};
	},

	campaignsUpdateBegin: () => {
		return {
			type: actions.CAMPAIGNS_UPDATE_BEGIN,
		};
	},

	campaignsUpdateSuccess: () => {
		openNotificationWithIcon('success', 'Success');
		return {
			type: actions.CAMPAIGNS_UPDATE_SUCCESS,
		};
	},

	campaignsUpdateErr: (err) => {
		openNotificationWithIcon('error', 'Error');
		return {
			type: actions.CAMPAIGNS_UPDATE_ERR,
			err,
		};
	},

	campaignsDeleteBegin: () => {
		return {
			type: actions.CAMPAIGNS_DELETE_BEGIN,
		};
	},

	campaignsDeleteSuccess: () => {
		return {
			type: actions.CAMPAIGNS_DELETE_SUCCESS,
		};
	},

	campaignsDeleteErr: (err) => {
		return {
			type: actions.CAMPAIGNS_DELETE_ERR,
			err,
		};
	},

	campaignsSetTargetCountry: (data) => {
		return {
			type: actions.CAMPAIGNS_SET_TARGET_COUNTRY,
			data,
		};
	},

	campaignsSetTargetPlatform: (data) => {
		return {
			type: actions.CAMPAIGNS_SET_TARGET_PLATFORM,
			data,
		};
	},

	campaignsSetTargetOS: (data) => {
		return {
			type: actions.CAMPAIGNS_SET_TARGET_OS,
			data,
		};
	},

	campaignsSetBrandArray: (data) => {
		return {
			type: actions.CAMPAIGNS_SET_BRAND_ARRAY,
			data,
		};
	},
};

export default actions;
