import actions from './actions';

const {
	CAMPAIGN_TABLE_CHANGE_FILTER,
} = actions;

const initialState = {
	filterable: false,
};

const campaignTableReducer = (state = initialState, action) => {
	const { type, data, err } = action;

	switch (type) {

		case CAMPAIGN_TABLE_CHANGE_FILTER:
			return {
				...state,
				filterable: !state.filterable,
			};

		default:
			return state;
	}
};

export default campaignTableReducer;
