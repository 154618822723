import React, {useEffect, useState} from "react";
import {Switch} from "antd";
import {useSelector} from "react-redux";
import useSharedFunctions from "./SharedFunctions";
import {CaretRightOutlined, PauseOutlined} from "@ant-design/icons";

const SwitchComponent = ({ initialValue, campaign }) => {
    const { updateError, success, updateLoading } = useSelector((state) => {
        return {
            updateError: state.campaignsReducer.updateError,
            success: state.campaignsReducer.success,
            updateLoading: state.campaignsReducer.updateLoading,
        };
    });

    const { handleUpdateSwitch } = useSharedFunctions();

    const [innerValue, setInnerValue] = useState(initialValue);
    const [innerValueCache, setInnerValueCache] = useState(initialValue);

    useEffect(() => {
        setInnerValue(initialValue);
        setInnerValueCache(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (innerValue !== innerValueCache) {
            handleUpdateSwitch({ values: innerValueCache, campaign: campaign});
        }
    }, [innerValueCache]);

    useEffect(() => {
        if (updateError) {
            setInnerValueCache(innerValue);
        }
        if (success) {
            setInnerValue(innerValueCache);
        }
    }, [updateError, success]);


    return (
        <Switch
            style={{
                backgroundColor: innerValueCache ? '#1E8312' : '#7c7c7c',
            }}
            checkedChildren={<CaretRightOutlined />}
            unCheckedChildren={<PauseOutlined />}
            checked={innerValueCache}
            onChange={(value) => {
                setInnerValueCache(value);
            }}
            loading={updateLoading}
        />
    );
};

export default SwitchComponent;