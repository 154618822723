import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/dashboard'));
const ChangePassMail = lazy(() => import('../../container/dashboard/ChangePassMail'));
const Campaigns = lazy(() => import('../../container/dashboard/Campaigns'));
const IntegrationsRoute = lazy(() => import('../../container/dashboard/IntegrationsRoute'));
const NotFound = lazy(() => import('../../container/pages/404'));

function DashboardRoutes() {
	return (
		<Routes>
			<Route index element={<Dashboard />} />
			<Route path="changePassMail" element={<ChangePassMail />} />
			<Route path="campaigns" element={<Campaigns />} />
			<Route path="integrations/*" element={<IntegrationsRoute />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default DashboardRoutes;
