import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;
const authHeader = () => ({
	Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
	baseURL: API_ENDPOINT,
	headers: {
		Authorization: `Bearer ${getItem('access_token')}`,
		'Content-Type': 'application/json;charset=UTF-8',
	},
});

class DataService {
	static get(path = '', params = {}) {
		return client({
			method: 'GET',
			url: path,
			params: params,
			headers: { ...authHeader() },
		});
	}

	static post(path = '', data = {}, optionalHeader = {}) {
		return client({
			method: 'POST',
			url: path,
			data,
			headers: { ...authHeader(), ...optionalHeader },
		});
	}

	static patch(path = '', data = {}) {
		return client({
			method: 'PATCH',
			url: path,
			data: JSON.stringify(data),
			headers: { ...authHeader() },
		});
	}

	static put(path = '', data = {}) {
		return client({
			method: 'PUT',
			url: path,
			data: JSON.stringify(data),
			headers: { ...authHeader() },
		});
	}
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
	// do something before executing the request
	// For example tag along the bearer access token to request header or set a cookie
	const requestConfig = config;
	const { headers } = config;
	requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };

	return requestConfig;
});

client.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;
		if (response) {
			if (response.status === 500) {
				// do something here
			}
			else if (response.status === 401) {
				// Dispatch a custom event
				const event = new CustomEvent('unauthorized');
				window.dispatchEvent(event);
			}
			else {
				return response;
			}
		}
		return Promise.reject(error);
	},
);

export { DataService };
