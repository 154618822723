import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import {Checkbox, Form, Input, Row, Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {integrationAuthCreate} from "../redux/integrations/actionCreator";
import {AuthEventSelectColWrapper, AuthFormWrapper, AuthUserSelectWrapper} from "../container/styled";
import {integrationAccountListRead} from "../redux/integrationAccounts/actionCreator";

const steps = ['Connect', 'Integrate Account', 'Account Select', 'Postback Select'];

const EventSelectComponent = React.memo(({isDisabled, options}) => {
	return (
		<Select
			style={{minWidth: 200}}
			placeholder="Select a event"
			disabled={isDisabled}
			options={options}
		/>
	)
});

const AuthMenuModal = (
	{
		companyKey,
		activeStep,
		form,
		onFinishFailed,
		selectedAccount,
		setSelectedAccount,
		postback,
		setPostback,
		sendPayout,
		setSendPayout,
		addFutureAuths,
		setAddFutureAuths,
		sendFinalRevenue,
		setSendFinalRevenue,
	}
) => {
	const dispatch = useDispatch();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const handleAddAuthSubmit = useCallback(
		(values) => {
			const allValsExceptName = Object.keys(values).reduce((object, key) => {
				if (key !== 'keyName') {
					object[key] = values[key]
				}
				return object
			}, {})
			dispatch(integrationAuthCreate(allValsExceptName, values.keyName))
		},
		[dispatch],
	);

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	const handleReadAccountList = useCallback(
		(key, auth_id) => {
			dispatch(integrationAccountListRead(key, auth_id))
		}, [dispatch]);

	const {isLoading, accountList, accountsLoading} = useSelector((rootState) => {
		return {
			isLoading: rootState.integrations.loading,
			accountsLoading: rootState.integrationAccounts.loading,
			accountList: rootState.integrationAccounts.data,
		};
	});

	const [authId, setAuthId] = useState(1);

	useEffect(() => {
		if (activeStep === 2) {
			handleReadAccountList(companyKey, authId);
		}
	}, [activeStep]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const activeStepContent = () => {
		switch (activeStep) {
			case 0:
				return <h1>Connect</h1>;
			case 1:
				return (
					<AuthFormWrapper name="login" form={form} onFinish={handleAddAuthSubmit} layout="vertical" key={"form-add-auth"} onFinishFailed={onFinishFailed}>
						<Form.Item
							hidden="true"
							name="keyName"
							initialValue={companyKey}
						>
							<Input/>
						</Form.Item>
						<Form.Item
							name="alias"
							rules={[{
								message: 'Please input your alias!',
								required: true
							}]}
							label="Alias"
						>
							<Input placeholder="Place your alias here"/>
						</Form.Item>
						<Form.Item
							name="client_id"
							rules={[{
								message: 'Please input your Client Id!',
								required: true
							}]}
							label="Client Id"
						>
							<Input placeholder="Place your client id here"/>
						</Form.Item>
						<Form.Item
							name="client_secret"
							rules={[{
								message: 'Please input your Client Secret!',
								required: true
							}]}
							label="Client Secret"
						>
							<Input placeholder="Place your client secret here"/>
						</Form.Item>
					</AuthFormWrapper>
				);
			case 2:
				const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
				return (
					<AuthUserSelectWrapper>
						<h3>Select Your Account</h3>
						<Select
							showSearch
							style={{ minWidth: 200 }}
							placeholder="Select a account"
							filterOption={filterOption}
							onChange={(value) => {
								setSelectedAccount(value);
							}}
							value={selectedAccount}
							notFoundContent={accountsLoading ? <Spin size="small" /> : null}
							options={accountList && accountList.map((account) => {
								return { label: account.name, value: account.id }
							})}/>
						<Checkbox checked={addFutureAuths}
								  onChange={(e)=>{
										setAddFutureAuths(e.target.checked)
									}}
						>Include this account in all auths that may be opened in the future</Checkbox>
					</AuthUserSelectWrapper>
				)
			case 3:
				const pendingOptions = [
					{label: 'Revenue', value: 'revenue'},
					{label: 'Payout', value: 'payout'},
					{label: 'Revenue and Payout', value: 'revenue_payout'},
				];

				const finalOptions = [
					{label: 'Revenue', value: 'revenue'},
					{label: 'Payout', value: 'payout'},
					{label: 'Revenue and Payout', value: 'revenue_payout'},
				];

				return (
					<AuthUserSelectWrapper>
						<h3 style={{
							textAlign: 'center',
						}}>Choose your event</h3>
						<Row gutter={25}>
							<AuthEventSelectColWrapper xs={24} sm={12}>
								<div className={"bottom-checkboxes"} style={{marginBottom: 20}}>
									<Checkbox checked={postback} onChange={
										(e) => {
											setPostback(e.target.checked)
										}
									}>Send postback for pending revenue</Checkbox>
								</div>

								<EventSelectComponent isDisabled={!postback} options={pendingOptions}/>

								<div className={"bottom-checkboxes"}>
									<Checkbox
										disabled={!postback}
										checked={sendPayout}
										onChange={
										(e) => {
											setSendPayout(e.target.checked)
										}
									}>Send payout value</Checkbox>
								</div>
							</AuthEventSelectColWrapper>

							<AuthEventSelectColWrapper xs={24} sm={12}>
								<div className={"bottom-checkboxes"} style={{marginBottom: 20}}>
									<Checkbox checked={sendFinalRevenue} onChange={
										(e) => {
											setSendFinalRevenue(e.target.checked)
										}
									}>Send postback for final revenue</Checkbox>
								</div>

								<EventSelectComponent isDisabled={!sendFinalRevenue} options={finalOptions} />

								<div className={"bottom-checkboxes"}>
									<Checkbox
										disabled={!sendFinalRevenue}
										checked={sendPayout}
										onChange={
										(e) => {
											setSendPayout(e.target.checked)
										}
									}>Send payout value</Checkbox>
								</div>
							</AuthEventSelectColWrapper>
						</Row>
					</AuthUserSelectWrapper>
				);
			default:
				return 'Unknown step';
		}
	}

	return (
		<>
			<Stepper activeStep={activeStep} orientation={windowWidth < 576 ? 'vertical' : 'horizontal'}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{activeStep === steps.length ? (
				<React.Fragment>
					<Typography sx={{mt: 2, mb: 1}}>
						All steps completed - you&apos;re finished
					</Typography>
				</React.Fragment>
			) : (
				<Box style={{marginTop: 10}}>
					{
						isLoading ? (
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '200px'

							}}>
								<Spin/>
							</div>
						) : (
							activeStepContent()
						)
					}
				</Box>
			)}
		</>
	);
};


export default AuthMenuModal;
