import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './dashboard';
import withAdminLayout from '../../layout/withAdminLayout';
import { useDispatch, useSelector } from 'react-redux';
import { topMenuGetData } from '../../redux/topMenuGetUser/actionCreator';

const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const { error } = useSelector((state) => {
		return {
			error: state.topMenuGetUser.error,
		};
	});
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		dispatch(topMenuGetData());
	}, [dispatch]);

	useEffect(() => {
		if (error) {
			if (error.status === 401) {
				navigate('/');
			}
		}
	}, [error]);

	return (
		<Suspense
			fallback={
				<div className="spin">
					<Spin />
				</div>
			}
		>
			<Routes>
				<Route index path="/*" element={<Dashboard />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Suspense>
	);
});

export default withAdminLayout(Admin);
