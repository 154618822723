const actions = {
	CAMPAIGNS_TABS_BEGIN: 'CAMPAIGNS_TABS_BEGIN',
	CAMPAIGNS_TABS_CREATE_SUCCESS: 'CAMPAIGNS_TABS_CREATE_SUCCESS',
	CAMPAIGNS_TABS_ERR: 'CAMPAIGNS_TABS_ERR',

	CAMPAIGNS_SUBTABS_BEGIN: 'CAMPAIGNS_SUBTABS_BEGIN',
	CAMPAIGNS_SUBTABS_CREATE_SUCCESS: 'CAMPAIGNS_SUBTABS_CREATE_SUCCESS',
	CAMPAIGNS_SUBTABS_ERR: 'CAMPAIGNS_SUBTABS_ERR',

	CAMPAIGNS_SUBTABS_DELETE_BEGIN: 'CAMPAIGNS_SUBTABS_DELETE_BEGIN',
	CAMPAIGNS_SUBTABS_DELETE_SUCCESS: 'CAMPAIGNS_SUBTABS_DELETE_SUCCESS',
	CAMPAIGNS_SUBTABS_DELETE_ERR: 'CAMPAIGNS_SUBTABS_DELETE_ERR',

	CAMPAIGNS_TABS_DELETE_BEGIN: 'CAMPAIGNS_TABS_DELETE_BEGIN',
	CAMPAIGNS_TABS_DELETE_SUCCESS: 'CAMPAIGNS_TABS_DELETE_SUCCESS',
	CAMPAIGNS_TABS_DELETE_ERR: 'CAMPAIGNS_TABS_DELETE_ERR',

	CAMPAIGNS_TABS_SET_ACTIVE_KEY: 'CAMPAIGNS_TABS_SET_ACTIVE_KEY',
	CAMPAIGNS_SUBTABS_SET_ACTIVE_KEY: 'CAMPAIGNS_SUBTABS_SET_ACTIVE_KEY',

	CAMPAIGNS_TABS_SET_ACTIVE_INDEX: 'CAMPAIGNS_TABS_SET_ACTIVE_INDEX',
	CAMPAIGNS_SUBTABS_SET_ACTIVE_INDEX: 'CAMPAIGNS_SUBTABS_SET_ACTIVE_INDEX',

	campaignsTabsCreateBegin: () => {
		return {
			type: actions.CAMPAIGNS_TABS_BEGIN,
		};
	},

	campaignsTabsCreateSuccess: (data) => {
		return {
			type: actions.CAMPAIGNS_TABS_CREATE_SUCCESS,
			data,
		};
	},

	campaignsTabsErr: (err) => {
		return {
			type: actions.CAMPAIGNS_TABS_ERR,
			err,
		};
	},

	campaignsSubTabsCreateBegin: () => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_BEGIN,
		};
	},

	campaignsSubTabsCreateSuccess: (data) => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_CREATE_SUCCESS,
			data,
		};
	},

	campaignsSubTabsErr: (err) => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_ERR,
			err,
		};
	},

	campaignsSubTabsDeleteBegin: () => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_DELETE_BEGIN,
		};
	},

	campaignsSubTabsDeleteSuccess: (data) => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_DELETE_SUCCESS,
			data,
		};
	},

	campaignsSubTabsDeleteErr: (err) => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_DELETE_ERR,
			err,
		};
	},

	campaignsTabsDeleteBegin: () => {
		return {
			type: actions.CAMPAIGNS_TABS_DELETE_BEGIN,
		};
	},

	campaignsTabsDeleteSuccess: (data) => {
		return {
			type: actions.CAMPAIGNS_TABS_DELETE_SUCCESS,
			data,
		};
	},

	campaignsTabsDeleteErr: (err) => {
		return {
			type: actions.CAMPAIGNS_TABS_DELETE_ERR,
			err,
		};
	},

	campaignsTabsSetActiveKey: (data) => {
		return {
			type: actions.CAMPAIGNS_TABS_SET_ACTIVE_KEY,
			data,
		};
	},

	campaignsSubTabsSetActiveKey: (data) => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_SET_ACTIVE_KEY,
			data,
		};
	},

	campaignsTabsSetActiveIndex: (data) => {
		return {
			type: actions.CAMPAIGNS_TABS_SET_ACTIVE_INDEX,
			data,
		};
	},

	campaignsSubTabsSetActiveIndex: (data) => {
		return {
			type: actions.CAMPAIGNS_SUBTABS_SET_ACTIVE_INDEX,
			data,
		};
	},

	campaignsBrand: (data) => {
		return {
			type: actions.CAMPAIGNS_BRAND,
			data,
		};
	},

	campaignsRevenueBrandName: (data) => {
		return {
			type: actions.CAMPAIGNS_REVENUE_BRAND_NAME,
			data,
		};
	},

	campaignsTrafficBrandName: (data) => {
		return {
			type: actions.CAMPAIGNS_TRAFFIC_BRAND_NAME,
			data,
		};
	},
};

export default actions;
