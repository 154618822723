import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
	integrationAuthReadBegin,
	integrationAuthReadSuccess,
	integrationAuthReadErr,

	integrationAccountReadBegin,
	integrationAccountReadSuccess,
	integrationAccountReadErr,

	integrationUpdateBegin,
	integrationUpdateSuccess,
	integrationUpdateErr,

	integrationCreateBegin,
	integrationCreateSuccess,
	integrationCreateErr,
} = actions;

const integrationAuthCreate = (values, key) => {
	return async (dispatch) => {
		dispatch(integrationCreateBegin());
		try {
			key = key.toLowerCase();
			const response = await DataService.post('/ts/' + key + '/auth', values);
			if (response.status !== 200) {
				dispatch(integrationCreateErr(response.data.message));
			} else {
				dispatch(integrationCreateSuccess(true));
			}
		} catch (err) {
			dispatch(integrationCreateErr(err.message));
		}
	};
};

const integrationAuthListRead = (key) => {
	return async (dispatch) => {
		dispatch(integrationAuthReadBegin());
		try {
			const response = await DataService.get('/ts/' + key + '/auths');
			if (response.status !== 200) {
				dispatch(integrationAuthReadErr(response.data.message));
			} else {
				const categorizedData = { key: key, rest: response.data.data };
				dispatch(integrationAuthReadSuccess(categorizedData));
			}
		} catch (err) {
			dispatch(integrationAuthReadErr(err));
		}
	};
};

export { integrationAuthCreate, integrationAuthListRead };
