import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { lazy, useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';

import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import { theme } from './config/theme/themeVariables';
import {logOut} from "./redux/authentication/actionCreator";

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
	const dispatch = store.dispatch;

	const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
		return {
			rtl: state.ChangeLayoutMode.rtlData,
			topMenu: state.ChangeLayoutMode.topMenu,
			mainContent: state.ChangeLayoutMode.mode,
			isLoggedIn: state.auth.login,
		};
	});

	const [path, setPath] = useState(window.location.pathname);

	document.body.style.backgroundColor = theme[mainContent]['main-background'];

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			setPath(window.location.pathname);
		}
		return () => {
			unmounted = true;
		};
	}, [setPath]);

	useEffect(() => {
		const handleUnauthorized = () => {
			dispatch(logOut());
		};

		window.addEventListener('unauthorized', handleUnauthorized);

		// Cleanup
		return () => {
			window.removeEventListener('unauthorized', handleUnauthorized);
		};
	}, [dispatch]);

	return (
		<ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
			<ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
				<>
					<Router basename={process.env.PUBLIC_URL}>
						{!isLoggedIn ? (
							<Routes>
								<Route path="/*" element={<Auth />} />{' '}
							</Routes>
						) : (
							<Routes>
								<Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						)}
						{isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
							<Routes>
								<Route path="/" element={<Navigate to="/admin" />} />
							</Routes>
						)}
					</Router>
				</>
			</ThemeProvider>
		</ConfigProvider>
	);
}

function App() {
	return (
		<Provider store={store}>
			<ProviderConfig />
		</Provider>
	);
}

export default App;
