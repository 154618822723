import actions from './actions';
import CampaignTable from '../../container/dashboard/overview/campaign/campaignTable/CampaignTable';

const {
	CAMPAIGNS_TABS_CREATE_BEGIN,
	CAMPAIGNS_TABS_CREATE_SUCCESS,
	CAMPAIGNS_TABS_ERR,

	CAMPAIGNS_TABS_DELETE_BEGIN,
	CAMPAIGNS_TABS_DELETE_SUCCESS,
	CAMPAIGNS_TABS_DELETE_ERR,

	CAMPAIGNS_SUBTABS_CREATE_BEGIN,
	CAMPAIGNS_SUBTABS_CREATE_SUCCESS,
	CAMPAIGNS_SUBTABS_CREATE_ERR,

	CAMPAIGNS_SUBTABS_DELETE_BEGIN,
	CAMPAIGNS_SUBTABS_DELETE_SUCCESS,
	CAMPAIGNS_SUBTABS_DELETE_ERR,

	CAMPAIGNS_TABS_SET_ACTIVE_KEY,
	CAMPAIGNS_SUBTABS_SET_ACTIVE_KEY,

	CAMPAIGNS_TABS_SET_ACTIVE_INDEX,
	CAMPAIGNS_SUBTABS_SET_ACTIVE_INDEX,

} = actions;

const initialState = {
	loading: false,
	tableTabs: [
		{
			label: 'Campaigns',
			key: 'campaigns',
			children: [<CampaignTable />],
			closable: false,
		}
	],
	subTableTabs: [],
	error: null,
	activeKey: 'campaigns',
	activeIndex: 0,
	subActiveKey: 'daily',
	subActiveIndex: 0,
};

const campaignsTabReducer = (state = initialState, action) => {
	const { type, data, err } = action;
	switch (type) {
		case CAMPAIGNS_TABS_CREATE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_TABS_CREATE_SUCCESS:
			return {
				...state,
				tableTabs: [...state.tableTabs, data],
				loading: false,
				activeKey: data.key,
			};

		case CAMPAIGNS_TABS_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case CAMPAIGNS_TABS_DELETE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_TABS_DELETE_SUCCESS:
			return {
				...state,
				loading: false,
				tableTabs: state.tableTabs.filter((tab) => tab.key !== data),
				subTableTabs: state.subTableTabs.filter((tab) => tab.id !== data),
				activeIndex: state.activeIndex === state.tableTabs.length - 1 ? state.activeIndex - 1 : state.activeIndex,
				activeKey: state.tableTabs[0].key,
			};

		case CAMPAIGNS_TABS_DELETE_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case CAMPAIGNS_SUBTABS_CREATE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_SUBTABS_CREATE_SUCCESS:
			return {
				...state,
				subTableTabs: [...state.subTableTabs, data],
				loading: false,
			};

		case CAMPAIGNS_SUBTABS_CREATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case CAMPAIGNS_SUBTABS_DELETE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case CAMPAIGNS_SUBTABS_DELETE_SUCCESS:
			return {
				...state,
				loading: false,
				subTableTabs: [],
			};

		case CAMPAIGNS_SUBTABS_DELETE_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};


		case CAMPAIGNS_TABS_SET_ACTIVE_KEY:
			return {
				...state,
				activeKey: data,
			};

		case CAMPAIGNS_SUBTABS_SET_ACTIVE_KEY:
			return {
				...state,
				subActiveKey: data,
			};

		case CAMPAIGNS_TABS_SET_ACTIVE_INDEX:
			return {
				...state,
				activeIndex: data,
			};

			case CAMPAIGNS_SUBTABS_SET_ACTIVE_INDEX:
			return {
				...state,
				subActiveIndex: data,
			};

		default:
			return state;
	}
};

export default campaignsTabReducer;
