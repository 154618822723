import PropTypes from "prop-types";
import {StyledTableTabPanel} from "./style";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <StyledTableTabPanel
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={'tab-panel'}
            {...other}
        >
            {value === index && (
                <Box style={{
                    // paddingTop: value === 0 ? '0px' : '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1
                }}>
                    {children}
                </Box>
            )}
        </StyledTableTabPanel>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export {CustomTabPanel};