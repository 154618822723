import Styled from 'styled-components';

const EChartCard = Styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .card-chunk{
        width: 50%;
        flex: 0 0 50%;
        h1{
            color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
        }
    }
    @media only screen and (max-width: 379px){
        .card-chunk{
            width: 100%;
            flex: 0 0 100%;
            h1{
                margin-bottom: 0;
            }
            p{
                margin: 5px 0 20px 0;
            }
        }
    }
    .chartjs-tooltip {
        min-width: 132px !important;
    }
`;

const DashboardBaseStyleWrap = Styled.div`
	.ant-tabs-tab{
		margin-bottom: 100px;
	}
	.antd-campaign-top-toolbar{
        align-self: center;
        margin-left: auto;
        display: flex;
        flex-direction: row;
        *:not(svg) {
            font-size: 12px;
            font-family: 'Roboto', sans-serif;
        }
    }
    .antd-campaign-table-bid{
        background-color: ${({ theme }) => theme[theme.mainContent]['main-background']};
        padding-inline: 0.5rem;
        border-radius: 0.5rem;
        justify-content: space-evenly;
        display: flex;
        align-items: center;
            svg {
                color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            }
    }
    
    .MuiTab-root{
        background-color: transparent;
        margin: 0px;
        border-radius: 0px;
        color: ${({ theme }) => theme['primary-color']} !important;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        text-transform: none;
        padding: 0 6px;
        border: 2px solid ${({ theme }) => theme[theme.mainContent]['border-color-tab']};
        border-bottom: none;
        .tab-close-icon {
            color: ${({ theme }) => theme['error-color']} !important;
        }
    }
    
    .Mui-selected{
        background-color: ${({ theme }) => theme['primary-color']}26 !important;
    }
    
    .MuiTabs-flexContainer{
        background-color: ${({ theme }) => theme[theme.mainContent]['main-background']} !important;
    }
    
    .MuiTabs-indicator{
        background-color: ${({ theme }) => theme['primary-color']} !important;
    }
    
    .InovuaReactDataGrid__column-header__filter-wrapper{
        background-color: ${({ theme }) => theme[theme.mainContent]['darker1-background']};
    }
    
    .MuiIconButton-root{
        padding: 0;
    }
`;

const CampaignsTableWrap = Styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    .InovuaReactDataGrid__body{
        font-size: 12px;
    }
    .inovua-react-toolkit-combo-box{
        font-size: 12px;
    }
    
    .InovuaReactDataGrid__cell{
        padding: 0px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        border-top-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    }
    .antd-campaign-table-bid-title{
        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        font-weight: bold;
    }
    .antd-campaign-table-bid-value{
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
    .InovuaReactDataGrid__row{
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
    .InovuaReactDataGrid__row--odd{
        background-color: ${({ theme }) => theme[theme.mainContent]['table-bg-odd']};
    }
    .InovuaReactDataGrid__row--even{
        background-color: ${({ theme }) => theme[theme.mainContent]['table-bg-even']};
    }
    .InovuaReactDataGrid__column-header__content{
        padding: 4px;
        white-space: break-spaces;
        height: 100%;
    }
    .InovuaReactDataGrid__header-group__title{
        padding: 4px;
    }
    .InovuaReactDataGrid__header{
        background-color: ${({ theme }) => theme[theme.mainContent]['darker1-background']};
        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
    }
    .InovuaReactDataGrid__column-header--show-border-left{
        border-left-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    }
    .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left{
        border-left-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    }
    .InovuaReactDataGrid__cell--show-border-left{
        border-left-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    }
    .InovuaReactDataGrid__cell--show-border-bottom{
        border-bottom: 2px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
    }
    
    .inovua-react-pagination-toolbar{
        background-color: ${({ theme }) => theme[theme.mainContent]['darker1-background']};
        border-top-color: ${({ theme }) => theme[theme.mainContent]['border-color-default']} !important;
        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
    }
    .antd-campaign-sub-table-open-button{
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            width: min-content;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
       
    }
    .InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover{
        background-color: ${({ theme }) => theme[theme.mainContent]['table-bg-hover']};
        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
    }
    
    .component-edit-button{
        visibility: hidden;
    }
    
    .InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover .component-edit-button{
        visibility: visible;
    }
    
    .InovuaReactDataGrid--theme-default-light{
        border: 0
    }
`;

const UserOverviewStyle = Styled.div`
    .ant-card{
        min-height: 458px;
        @media only screen and (max-width: 1599px){
            min-height: auto;
        }
    }
`;

const CardBarChart2 = Styled.div`
    @media only screen and (max-width: 379px){
        text-align: center
    }
    h1{
        margin-bottom: 5px;
    }
    @media only screen and (max-width: 1500px){
        h1{
            font-size: 22px;
        }
    }
    & > span{
        font-size: 14px;
        color: ${({ theme }) => theme[theme.mainContent]['light-gray-text']};
    }
    p{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 21px 0 -6px 0;
        @media only screen and (max-width: 379px){
            justify-content: center;
        }
        .growth-upward, .growth-downward{
            display: inline-flex;
            align-items: center;
            ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px;
            font-weight: 600;

            svg{
                width: 15px;
            }
        }
        .growth-upward{
            color: ${({ theme }) => theme['success-color']};
            svg{
                color: ${({ theme }) => theme['success-color']};
            }
        }
        .growth-downward{
            color: ${({ theme }) => theme['danger-color']};
            svg{
                color: ${({ theme }) => theme['danger-color']};
            }
        }
        span{
            color: ${({ theme }) => theme[theme.mainContent]['light-gray-text']};
            font-size: 14px;
            display: inline-block;
        }
    }
`;

const SalesRevenueWrapper = Styled.div`
    .ninjadash-sales-revenue-lineChart{
        margin: ${({ theme }) => (!theme.rtl ? '0 -4px 0 0' : '0 0 0 -4px')};
    }
    .ninjadash-chart-top{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -12px;
        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin: -6px;
        }
    }
    #ninjadash-sales-revenue{
        margin-top: 12px;
    }
    .ninjadash-chart-top__item{
        position: relative;
        display: flex;
        align-items: center;
        margin: 12px;
        padding-left: 12px;
        @media only screen and (max-width: 767px) {
            margin: 6px;
        }
        &:before{
            position: absolute;
            width: 7px;
            height: 7px;
            left: 0;
            top: 50%;
            border-radius: 50%;
            content: '';
            transform: translateY(-50%);
        }
        &.ninjadash-chart-top__item-order{
            &:before{
                background-color: ${({ theme }) => theme['primary-color']};
            }
            
        }
        &.ninjadash-chart-top__item-sale{
            &:before{
                background-color: ${({ theme }) => theme['info-color']};
            }
           
        }
        .ninjadash-chart-top__item--text{
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        }
        .ninjadash-chart-top__item--amount{
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            margin: 0 5px 0 10px;
            color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
            @media only screen and (max-width: 767px) {
                font-size: 18px;
            }
        }
        .ninjadash-chart-top__item--status{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            svg{
                height: 20px;
                width: 20px;
            }
            &.status-growth{
                color: ${({ theme }) => theme['success-color']};
                svg{
                    path{
                        fill: ${({ theme }) => theme['sucess-color']};
                    }
                }
            }
            &.status-down{
                color: ${({ theme }) => theme['danger-color']};
                svg{
                    path{
                        fill: ${({ theme }) => theme['danger-color']};
                    }
                }
            }
        }
    }
`;

const ChartContainer = Styled.div`
    display: block;
    font-family: 'Jost', sans-serif;
    &.ninjadash-chart-pie{
        .chartjs-tooltip {
            padding: 4px !important;
            table tbody td{
                color: #000;
            }
        }
    }
    .chart-divider {
        display: block;
        width: 100%;
        height: 100px;
    }
    .chartjs-tooltip {
        opacity: 1;
        position: absolute;
        background: ${({ theme }) => theme[theme.mainContent]['white-background']};
        box-shadow: 0 3px 16px #ADB5D915;
        padding: 8px 6px !important;
        border-radius: 5px;
        border: 1px solid ${({ theme }) => theme[theme.mainContent]['border-color-default']};
        min-width: 80px;
        transition: all 0.5s ease;
        pointer-events: none;
        transform: translate(-50%, 5%);
        z-index: 222;
        top: 0;
        left: 0;
        @media only screen and (max-width: 991px){
            transform: translate(-60%, 5%);
        }
        &:before {
            position: absolute;
            content: '';
            border-top: 5px solid ${({ theme }) => theme[theme.mainContent]['dark-background']};
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            bottom: -5px;
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 50%;
            transform: translateX(-50%);
        }
    }
    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: "pink";
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}
        : 5px;
    }
    .tooltip-title {
        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        font-size: 12px;
        line-height: 1;
        font-weight: 500 !important;
        font-family: 'Jost', sans-serif;;
        text-transform: capitalize;
        margin-bottom: 4px;
    }
    .tooltip-value {
        color: #63b963;
        font-size: 22px;
        font-weight: 600 !important;
        font-family: 'Jost', sans-serif;;
    }
    .tooltip-value sup {
        font-size: 12px;
        @media only screen and (max-width: 1199px){
            font-size: 11px;
        }
    }
    table{
        tbody{
            td{
                font-size: 12px;
                font-weight: 500;
                padding-bottom: 3px;
                color: ${({ theme }) => theme[theme.mainContent]['extra-light-text']};
                .data-label{
                    ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 3px;
                    color: ${({ theme }) => theme[theme.mainContent]['light-gray-text']};
                }
            }
        }
    }
`;

const TrafficSourceListWrap = Styled.div`
	.ant-list-item {
		padding: 0;
	}
	
	.ant-list-items li {
	    justify-content: flex-start;
	    margin-bottom: 0px !important;
	    display: flex;
	    color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
	}
	
	.ant-btn-default{
		background-color: ${({ theme }) => theme['success-color']};
	}
`;

const TrafficSourceCardWrap = Styled.div`
	.btn-add{
		background: ${({ theme }) => theme['success-color']};
		border-width: 0;
	}
`;

export {
    ChartContainer,
    DashboardBaseStyleWrap,
    EChartCard,
    UserOverviewStyle,
    CardBarChart2,
    SalesRevenueWrapper,
    TrafficSourceListWrap,
    TrafficSourceCardWrap,
    CampaignsTableWrap,
};
