import React from 'react';
import {DashboardBaseStyleWrap} from '../../Style';
import {useDispatch, useSelector} from 'react-redux';
import {campaignsChangeSubActiveIndex,} from '../../../../redux/campaignsTabs/actionCreator';
import Tabs from "@mui/material/Tabs";
import {Tab} from "@mui/material";
import {CustomTabPanel} from "../../../../components/custom-tab-panel/customTabPanel";

const CampaignSubTableCard = React.memo(() => {
    const { subTableTabs, subActiveIndex, activeIndex } = useSelector((state) => {
        return {
            subTableTabs: state.campaignsTabReducer.subTableTabs,
            subActiveIndex: state.campaignsTabReducer.subActiveIndex,
            activeIndex: state.campaignsTabReducer.activeIndex,
        };
    });
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        dispatch(campaignsChangeSubActiveIndex(newValue));
    };

    return (
        <DashboardBaseStyleWrap style={{flex: 1, display: "flex", flexDirection: "column"}}>
            <Tabs
                value={subActiveIndex}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: { display: 'none' }
                }}
                centered
            >
                {
                    subTableTabs[activeIndex - 1].children.map((tab, index) => (
                        <Tab
                            disableRipple={tab.closable}
                            wrapped={true}
                            label={tab.label}
                            id={tab.key}
                        />
                    ))
                }
            </Tabs>
            {
                subTableTabs[activeIndex - 1].children.map((tab, index) => (
                    <CustomTabPanel value={subActiveIndex} index={index}>
                        {tab.children}
                    </CustomTabPanel>
                ))
            }
        </DashboardBaseStyleWrap>

    );
});

export default CampaignSubTableCard;
