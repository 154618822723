import actions from './actions';
import CampaignSubTable from '../../container/dashboard/overview/campaign/campaignSubTable/CampaignSubTable';

const {
	campaignsTabsCreateBegin,
	campaignsTabsCreateSuccess,
	campaignsTabsErr,

	campaignsTabsDeleteBegin,
	campaignsTabsDeleteSuccess,
	campaignsTabsDeleteErr,

	campaignsSubTabsCreateBegin,
	campaignsSubTabsCreateSuccess,
	campaignsSubTabsErr,

	campaignsSubTabsDeleteBegin,
	campaignsSubTabsDeleteSuccess,
	campaignsSubTabsDeleteErr,

	campaignsTabsSetActiveKey,
	campaignsSubTabsSetActiveKey,

	campaignsTabsSetActiveIndex,
	campaignsSubTabsSetActiveIndex,
} = actions;

const campaignsAddTab = (value) => {
	return async (dispatch) => {
		try {
			dispatch(campaignsTabsCreateBegin());
			dispatch(campaignsTabsCreateSuccess(value));
		} catch (err) {
			dispatch(campaignsTabsErr(err));
		}
	};
};

const campaignsRemoveTab = (value) => {
	return async (dispatch) => {
		try {
			dispatch(campaignsTabsDeleteBegin());
			dispatch(campaignsTabsDeleteSuccess(value));
			// dispatch(campaignsSubTabsDeleteSuccess());
		} catch (err) {
			dispatch(campaignsTabsDeleteErr(err));
		}
	};
}

const campaignsChangeActiveKey = (value) => {
	return campaignsTabsSetActiveKey(value);
}

const campaignsChangeSubActiveKey = (value) => {
	return campaignsSubTabsSetActiveKey(value);
}

const campaignsChangeActiveIndex = (value) => {
	return async (dispatch) => {
		try {
			dispatch(campaignsTabsSetActiveIndex(value));
		} catch (err) {
			dispatch(campaignsTabsErr(err));
		}
	};
}

const campaignsChangeSubActiveIndex = (value) => {
	return async (dispatch) => {
		try {
			dispatch(campaignsSubTabsSetActiveIndex(value));
		} catch (err) {
			dispatch(campaignsSubTabsErr(err));
		}
	};
}

const campaignsAddSubTab = (value) => {
	const campaignId = value.id.split('_')[0];
	const company = value.campaign_type;

	return async (dispatch) => {
		try {
			dispatch(campaignsSubTabsCreateBegin());
			const subTabs = [
				{
					label: 'Daily',
					key: 'daily',
					children: <CampaignSubTable campaignId={campaignId} company={company} type={'daily'} />,
					closable: false,
				},
				{
					label: 'Creative',
					key: 'creative',
					children: <CampaignSubTable campaignId={campaignId} company={company} type={'creative'} />,
					closable: false,
				},
				{
					label: 'Hour',
					key: 'hour',
					children: <CampaignSubTable campaignId={campaignId} company={company} type={'hour'} />,
					closable: false,
				}
			];
			if (value.campaign_type === 'taboola'){
				subTabs.push({
					label: 'Publisher',
					key: 'publisher',
					children: <CampaignSubTable campaignId={campaignId} company={company} type={'publisher'} />,
					closable: false,
				});
			}

			const subTabOptions = {
				id: value.id,
				children: subTabs,
			};

			dispatch(campaignsSubTabsCreateSuccess(subTabOptions));

			// dispatch(campaignsSubTabsCreateSuccess(subTabs));
		} catch (err) {
			dispatch(campaignsSubTabsErr(err));
		}
	};
}



export { campaignsAddTab, campaignsRemoveTab, campaignsChangeActiveKey, campaignsAddSubTab, campaignsChangeSubActiveKey, campaignsChangeActiveIndex, campaignsChangeSubActiveIndex };
