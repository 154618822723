import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore';
import rootReducer from './rootReducers';
import fbConfig from '../config/database/firebase';

const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(thunk.withExtraArgument({ getFirestore, storage: firebase.storage })),
		reduxFirestore(fbConfig),
	),
);

export const rrfProps = {
	firebase,
	config: (fbConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true }),
	dispatch: store.dispatch,
	createFirestoreInstance,
};

export default store;
