import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { subCampaignsListRead } from '../../../../../redux/subCampaigns/actionCreator';
import {CampaignsTableWrap} from '../../../Style';
import SwitchComponent from "../../../../../components/campaigns-table-components/SwitchComponent";
import NumericStepperComponent from "../../../../../components/campaigns-table-components/NumericStepperComponent";

const CampaignSubTable = ({ company, type, campaignId }) => {
    const dispatch = useDispatch();

    const { data, loading } = useSelector((state) => {
        return {
            data: state.subCampaignsReducer.data,
            loading: state.subCampaignsReducer.loading,
        };
    });

    const [innerLoading, setInnerLoading] = useState(false);

    const fetchData = useCallback(
        (values) => {
            dispatch(subCampaignsListRead({ company, type, campaignId }));
        },
        [campaignId, company, dispatch, type],
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function getColumnsAndGroups(data) {
        let columns = [];
        let groups = [];

        if (data && data.length > 0) {
            setInnerLoading(true);
            // Get the keys of the first object in data
            const keys = Object.keys(data[0]);

            const index = keys.indexOf('rs_name');
            if (index > -1) {
                keys.splice(index, 1);
            }

            // Map over the keys to create the column objects
            columns = keys.map(key => {
                var columnOptions = {};
                if (key.includes('image_url')){
                    columnOptions = {
                        name: key,
                        header: "Image",
                        resizable: false,
                        width: 210,
                        render: ({ value }) => {
                            return (
                                <div
                                    style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <img
                                        src={value}
                                        alt="creative"
                                        style={{
                                            borderRadius: 5,
                                            width: '100%',
                                            maxHeight: 100,
                                        }}
                                    />
                                </div>
                            )
                        },
                    };
                }
                else if (key === 'active' || key === 'is_active') {
                    columnOptions = {
                        name: key,
                        header: 'Active',
                        render: ({ value, data }) => {
                            data.campaign_type = company;
                            data.type = type;
                            return (
                                <SwitchComponent
                                    initialValue={value}
                                    campaign={data}
                                />
                            );
                        },
                    };
                }
                else if (key === 'publisher_bid') {
                    columnOptions = {
                        name: key,
                        header: 'Bid',
                        render: ({ value, data }) => {
                            data.campaign_type = company;
                            data.type = type;
                            return (
                                <NumericStepperComponent
                                    initialValue={value}
                                    campaign={data}
                                />
                            );
                        },
                    };
                }
                else if (key.startsWith('creative_')) {
                    columnOptions = {
                        name: key,
                        header: key.charAt(9).toUpperCase() + key.slice(10),
                        group: 'creative',
                    };
                }
                else if (key.startsWith('publisher_')) {
                    columnOptions = {
                        name: key,
                        header: key.charAt(10).toUpperCase() + key.slice(11),
                        group: 'publisher',
                    };
                }
                else if (key.startsWith('rs_')) {
                    columnOptions = {
                        name: key,
                        header: key.charAt(3).toUpperCase() + key.slice(4),
                        group: 'rs',
                        minWidth: 100,
                    };
                }
                else if (key.startsWith('ts_')) {
                    columnOptions = {
                        name: key,
                        header: key.charAt(3).toUpperCase() + key.slice(4),
                        group: 'ts',
                        minWidth: 100,
                    };
                }
                else if (key.startsWith('ab_')) {
                    columnOptions = {
                        name: key,
                        header: key.charAt(3).toUpperCase() + key.slice(4),
                        group: 'ab',
                        minWidth: 100,
                    };
                }
                else {
                    columnOptions = {
                        name: key,
                        header: key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
                    };
                }
                return columnOptions;
            });

            // Create groups based on column names
            groups = keys.reduce((acc, key) => {
                if (key.startsWith('rs_')) {
                    acc.push({ name: 'rs', header: 'Revenue Source' });
                } else if (key.startsWith('ts_')) {
                    acc.push({ name: 'ts', header: 'Traffic Source' });
                }
                else if (key.startsWith('ab_')) {
                    acc.push({ name: 'ab', header: 'Arbitrax' });
                }
                return acc;
            }, []);
            setInnerLoading(false);
        }

        const sortingFunction = (p1, p2, column) => {
            try {
                const numA = parseFloat(p1.toString().replace('%', '').replace(',', '.').replace(/\./g, ''));
                const numB = parseFloat(p2.toString().replace('%', '').replace(',', '.').replace(/\./g, ''));

                return numA - numB;
            }
            catch (e) {
                console.error("deneme: ERROR: ", e)
                console.log("deneme: p1: ", p1, "\np2: ", p2, "\ncolumn: ", column)
                return 0;
            }
        }

        columns.forEach((column) => {
            if (column.type === 'number') {
                column.sort = sortingFunction;
            }
        });


        // Return both columns and groups
        return { columns, groups };
    }

    const { columns, groups } = useMemo(() => getColumnsAndGroups(data), [data]);

    const gridStyle = {
        flex: 1,
    };

    const rowHeight = useMemo(() => {
        if (type === 'creative') {
            return 150;
        } else {
            return 35;
        }
    }, [type]);

    return (
        <CampaignsTableWrap>
            {
                <ReactDataGrid
                    enableColumnAutosize
                    idProperty="id"
                    columns={columns}
                    dataSource={data}
                    rowHeight={rowHeight}
                    pagination
                    showZebraRows={true}
                    groups={groups}
                    style={gridStyle}
                    loading={innerLoading || loading}
                />
            }
        </CampaignsTableWrap>
    )
};

export default CampaignSubTable;
