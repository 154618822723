import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { campaignsListRead } from '../../../../../redux/campaigns/actionCreator';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import { CampaignsTableWrap } from '../../../Style';
import {
    campaignsAddSubTab,
    campaignsAddTab,
    campaignsChangeActiveIndex,
    campaignsChangeActiveKey,
} from '../../../../../redux/campaignsTabs/actionCreator';
import CampaignSubTableCard from '../CampaignSubTableCard';
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import treeFilter from "@inovua/reactdatagrid-community/treeFilter";
import {SwSettings} from "../../../../../components/utilities/icons";
import NumericStepperComponent from "../../../../../components/campaigns-table-components/NumericStepperComponent";
import SwitchComponent from "../../../../../components/campaigns-table-components/SwitchComponent";
import OsTargetRender from "../../../../../components/campaigns-table-components/os-target-render";
import PlatformTargetRender from "../../../../../components/campaigns-table-components/platform-target-render";
import CountryTargetRender from "../../../../../components/campaigns-table-components/country-target-render";
import CampaignTypeRender from "../../../../../components/campaigns-table-components/campaign-type-render";
import CampaignBudgetRender from "../../../../../components/campaigns-table-components/campaign-budget-render";

const CampaignTable = React.memo(() => {
    const dispatch = useDispatch();
    const { data, isLoading, tableTabs, lastFetchTime, filterable } = useSelector((state) => {
        return {
            data: state.campaignsReducer.data,
            isLoading: state.campaignsReducer.loading,
            tableTabs: state.campaignsTabReducer.tableTabs,
            lastFetchTime: state.campaignsReducer.lastFetchTime,
            filterable: state.campaignTableReducer.filterable,
        };
    });

    const [dataSource, setDataSource] = useState(data);
    const [uniqueCountry, setUniqueCountry] = useState([]);
    const [platformTargeting, setPlatformTargeting] = useState([]);
    const [osTargeting, setOsTargeting] = useState([]);
    const [editableRowId, setEditableRowId] = useState(null);

    const fetchData = useCallback(() => {
        dispatch(campaignsListRead());
    }, [dispatch]);

    useEffect(() => {
        // Fetch data if lastFetchTime is null or more than 1 minutes
        if (lastFetchTime === null || new Date() - lastFetchTime > 60000){
            fetchData();
        }
    }, []);

    const onFilterValueChange = (filterValue) => {
        setFilterValue(filterValue);
        const filteredData = treeFilter(data, filterValue, filterTypes);
        setDataSource(filteredData);
    };

    const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
        select_custom_contains: {
            name: 'select_custom_contains',
            operators: [
                {
                    name: 'custom_contains',
                    fn: ({value, filterValue, data}) => {
                        if (filterValue === null) {
                            return true;
                        }
                        if (value === undefined || value === null) {
                            return false;
                        }

                        const lowerCaseValue = value.map(v => v.toLowerCase());
                        return filterValue.every(os => {
                            return lowerCaseValue.includes(os.toLowerCase());
                        });
                    },
                }
            ],
        },
        custom_platform_contains: {
            name: 'custom_platform_contains',
            operators: [
                {
                    name: 'custom_contains',
                    fn: ({value, filterValue, data}) => {
                        if (filterValue === null) {
                            return true;
                        }
                        if (filterValue === "empty"){
                            return value === undefined || value === null;
                        }
                        if (value === undefined || value === null) {
                            return false;
                        }

                        const lowerCaseValue = value.map(v => v.toLowerCase());

                        return filterValue.every(platform => {
                            if (platform === "Mobile" || platform === "PHON"){
                                return value.includes("Mobile") || value.includes("PHON");
                            }
                            else{
                                return lowerCaseValue.includes(platform.toLowerCase());
                            }
                        });

                    },
                }
            ],
        },
    });

    const columns = [
        {
            name: 'actions',
            header: '',
            lockable: false,
            showColumnMenuTool: true,
            width: 45,
            resizable: false,
            render: ({ data }) => {
                if (data.campaign_type == null) {
                    return null
                }
                return (
                    <div
                        className={'antd-campaign-sub-table-open-button'}
                        onClick={() => {
                            if (!tableTabs.find((tab) => tab.key === data.id)) {
                                if (data.id.includes('/')){
                                    data.id = data.id.split('/')[1];
                                }
                                dispatch(campaignsAddSubTab(data));
                                dispatch(campaignsAddTab({
                                    label: data.name,
                                    key: data.id,
                                    children: <CampaignSubTableCard />,
                                    closable: true,
                                    brand: data.campaign_type,
                                    rs_name: data.rs_name,
                                    ts_name: data.ts_name,
                                }));
                                dispatch(campaignsChangeActiveKey(data.id));
                                dispatch(campaignsChangeActiveIndex(tableTabs.length));
                            } else {
                                dispatch(campaignsChangeActiveKey(data.id));
                                dispatch(campaignsChangeActiveIndex(tableTabs.findIndex((tab) => tab.key === data.id)));
                            }
                        }}>
                        <SwSettings width={16} height={16}/>
                    </div>
                );
            },
        },
        {
            name: 'campaign_type',
            header: 'Sources',
            lockable: false,
            width: 90,
            resizable: false,
            render: ({ value, data }) => {
                return(
                    <CampaignTypeRender value={value} data={data} />
                )
            }
        },
        {
            name: 'name',
            header: 'Name',
            lockable: false
        },
        {
            name: 'bid',
            header: 'Bid',
            lockable: false,
            resizable: false,
            type: 'number',
            render: ({ value, data }) => {
                return (
                    <NumericStepperComponent
                        initialValue={value}
                        campaign={data}
                    />
                )
            },
        },
        {
            name: 'daily_cap',
            header: 'Daily cap',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
            render: ({ value, data }) => {
                return (
                    <CampaignBudgetRender value={value} data={data} editableRowId={editableRowId} setEditableRowId={setEditableRowId} />
                )
            },
        },
        {
            name: 'country_targeting',
            header: 'Country',
            lockable: false,
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Country',
                multiple: true,
                wrapMultiple: false,
                dataSource: uniqueCountry.map(c => {
                    return { id: c, label: c }
                }),

            },
            enableColumnFilterContextMenu: false,
            render: ({ value }) => {
                return(
                    <CountryTargetRender value={value} />
                )
            }
        },
        {
            name: 'platform_targeting',
            header: 'Platform',
            lockable: false,
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'Platform',
                multiple: true,
                wrapMultiple: false,
                dataSource: platformTargeting.map(c => {
                    if (c == null){
                        return { id: "empty", label: 'None' }
                    }
                    else{
                        if (c === "PHON" || c === "Mobile"){
                            return { id: c, label: 'Phone' }
                        }
                        else if (c === "TBLT"){
                            return { id: c, label: 'Tablet' }
                        }
                        else if (c === "DESK"){
                            return { id: c, label: 'Desktop' }
                        }
                        else{
                            return { id: c, label: c }

                        }
                    }
                }),

            },
            enableColumnFilterContextMenu: false,
            render: ({ value }) => {
                return (
                    <PlatformTargetRender value={value} />
                )
            }
        },
        {
            name: 'os_targeting',
            header: 'Os',
            lockable: false,
            filterEditor: SelectFilter,
            filterEditorProps: {
                placeholder: 'OS',
                multiple: true,
                dataSource: osTargeting.map(c => {
                    if (c == null){
                        return { id: "empty", label: 'None' }
                    }
                    else{
                        return { id: c, label: c }
                    }
                }),
                wrapMultiple: false,
            },
            enableColumnFilterContextMenu: false,
            render: ({ value }) => {
                return (
                    <OsTargetRender value={value} />
                )
            }
        },
        {
            name: 'campaign_status',
            header: 'Status',
            lockable: false,
            width: 90,
            resizable: false,
            render: ({ value, data }) => {
                return (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <SwitchComponent
                            initialValue={value}
                            campaign={data}
                        />
                    </div>
                );
            }
        },
        {
            name: 'ts_impressions',
            group: 'traffic_source',
            header: 'Impressions',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ts_clicks',
            group: 'traffic_source',
            header: 'Clicks',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ts_ctr',
            group: 'traffic_source',
            header: 'CTR',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ts_cpc',
            group: 'traffic_source',
            header: 'CPC',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ts_cost',
            group: 'traffic_source',
            header: 'Cost',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        // {
        //     name: 'rs_name',
        //     group: 'revenue_source',
        //     header: 'Name',
        //     lockable: false,
        //     type: 'string',
        //     filterEditor: NumberFilter,
        // },
        {
            name: 'rs_conversions',
            group: 'revenue_source',
            header: 'Conversions',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'rs_epc',
            group: 'revenue_source',
            header: 'EPC',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'rs_revenue',
            group: 'revenue_source',
            header: 'Revenue',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ab_profit',
            group: 'arbitrax',
            header: 'Profit',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ab_roi',
            group: 'arbitrax',
            header: 'ROI',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
        {
            name: 'ab_cr',
            group: 'arbitrax',
            header: 'CR',
            lockable: false,
            type: 'number',
            filterEditor: NumberFilter,
        },
    ];

    const sortingFunction = (p1, p2, column) => {
        try {
            const numA = parseFloat(p1.toString().replace('%', '').replace(',', '.').replace(/\./g, ''));
            const numB = parseFloat(p2.toString().replace('%', '').replace(',', '.').replace(/\./g, ''));

            return numA - numB;
        }
        catch (e) {
            console.error("deneme: ERROR: ", e)
            console.log("deneme: p1: ", p1, "\np2: ", p2, "\ncolumn: ", column)
            return 0;
        }
    }

    columns.forEach((column) => {
        if (column.type === 'number') {
            column.sort = sortingFunction;
        }
    });

    const defaultFilterValue = [
        {
            name: 'id',
            operator: 'contains',
            type: 'string',
            value: null,
        },
        {
            name: 'name',
            operator: 'contains',
            type: 'string',
            value: null,
        },
        {
            name: 'daily_cap',
            operator: 'gt',
            type: 'number',
            value: null,
        },
        {
            name: 'country_targeting',
            operator: 'custom_contains',
            type: 'select_custom_contains',
            value: null,
        },
        {
            name: 'platform_targeting',
            operator: 'custom_contains',
            type: 'custom_platform_contains',
            value: null,
        },
        {
            name: 'os_targeting',
            operator: 'custom_contains',
            type: 'select_custom_contains',
            value: null,
        },
        {
            name: 'ts_impressions',
            operator: 'gt',
            type: 'number',
            value: null,
        },
        {
            name: 'ts_clicks',
            operator: 'gt',
            type: 'number',
            value: null,
        },
        {
            name: 'ts_spend',
            operator: 'gt',
            type: 'number',
            value: null,
        },
        {
            name: 'rs_clicks',
            operator: 'gt',
            type: 'number',
            value: null,
        },
        {
            name: 'rs_revenue',
            operator: 'gt',
            type: 'number',
            value: null,
        },
    ];
    const [filterValue,  setFilterValue] = useState(defaultFilterValue);
    const groups = [
        {
            name: 'traffic_source',
            header: 'Traffic Source',
            headerStyle: {
                textAlign: 'center',
            }
        },
        {
            name: 'revenue_source',
            header: 'Revenue Source',
            headerStyle: {
                textAlign: 'center',
            }
        },
        {
            name: 'arbitrax',
            header: 'Arbitrax',
            headerStyle: {
                textAlign: 'center',
            }

        },
    ];

    const gridStyle = {
        flex: 1,
    };

    useEffect(() => {
        setDataSource(data);
        setUniqueCountry([...new Set(data.map((item) => item.country_targeting).flat())]);
        setPlatformTargeting([
            ...new Set(
                data
                    .map((item) => {
                        return item.platform_targeting;
                    })
                    .flat()
                    .filter((item) => item !== undefined)
            ),
        ]);
        setOsTargeting([
            ...new Set(
                data
                    .map((item) => {
                        return item.os_targeting;
                    })
                    .flat()
                    .filter((item) => item !== undefined)
            ),
        ]);
    }, [data]);

    return (
        <CampaignsTableWrap>
            <>
                <ReactDataGrid
                    rowHeight={32}
                    style={gridStyle}
                    columns={columns}
                    dataSource={dataSource}
                    showColumnMenuTool={false}
                    groups={groups}
                    loading={isLoading}
                    defaultFilterValue={filterValue}
                    onFilterValueChange={onFilterValueChange}
                    filterTypes={filterTypes}
                    enableFiltering={filterable}
                    pagination
                    enableColumnAutosize
                    showCellBorders={'vertical'}
                    showZebraRows={true}
                />
            </>
        </CampaignsTableWrap>
    );
});

export default CampaignTable;