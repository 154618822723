import React from "react";

const CountryTargetRender = ({ value }) => {
    if (Array.isArray(value)){
        var icons = [];
        for (let i = 0; i < value.length; i++) {
            var src = "https://flagsapi.com/"+ value[i] +"/flat/24.png";
            icons.push(
                <img
                    key={value[i]}
                    src={src}
                    alt={value[i]}
                    style={{ width: '22px', height: '22px', marginRight: '5px' }}
                />
            )
        }
        return icons;
    }
}

export default CountryTargetRender;