import actions from './actions';

const {
	INTEGRATION_ACCOUNT_CREATE_BEGIN,
	INTEGRATION_ACCOUNT_CREATE_SUCCESS,
	INTEGRATION_ACCOUNT_CREATE_ERR,

	INTEGRATION_ACCOUNT_READ_BEGIN,
	INTEGRATION_ACCOUNT_READ_SUCCESS,
	INTEGRATION_ACCOUNT_READ_ERR,

	INTEGRATION_ACCOUNT_UPDATE_BEGIN,
	INTEGRATION_ACCOUNT_UPDATE_SUCCESS,
	INTEGRATION_ACCOUNT_UPDATE_ERR,
} = actions;

const initialState = {
	data: null,
	loading: false,
	error: null,
};

const integrationAccountsReducer = (state = initialState, action) => {
	const { type, data, err } = action;
	switch (type) {

		case INTEGRATION_ACCOUNT_READ_BEGIN:
			return {
				...state,
				loading: true,
			};

		case INTEGRATION_ACCOUNT_READ_SUCCESS:
			return {
				...state,
				data: data,
				loading: false,
			};

		case INTEGRATION_ACCOUNT_READ_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case INTEGRATION_ACCOUNT_CREATE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case INTEGRATION_ACCOUNT_CREATE_SUCCESS:
			return {
				...state,
				loading: false,
			};

		case INTEGRATION_ACCOUNT_CREATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		case INTEGRATION_ACCOUNT_UPDATE_BEGIN:
			return {
				...state,
				loading: true,
			};

		case INTEGRATION_ACCOUNT_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
			};

		case INTEGRATION_ACCOUNT_UPDATE_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};

		default:
			return state;
	}
};

export default integrationAccountsReducer;
