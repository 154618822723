import Cookies from 'js-cookie';
import actions from './actions';

const {
	LOGIN_BEGIN,
	LOGIN_SUCCESS,
	LOGIN_ERR,
	LOGOUT_BEGIN,
	LOGOUT_SUCCESS,
	LOGOUT_ERR,
	UPDATE_PASS_BEGIN,
	UPDATE_PASS_SUCCESS,
	UPDATE_PASS_ERR,
} = actions;

const initState = {
	login: Cookies.get('logedIn'),
	loading: false,
	error: null,
	updateError: null,
	updatePassSuccess: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
	const { type, data, err } = action;
	switch (type) {
		case LOGIN_BEGIN:
			return {
				...state,
				loading: true,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				login: data,
				loading: false,
			};
		case LOGIN_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};
		case LOGOUT_BEGIN:
			return {
				...state,
				loading: true,
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				login: data,
				loading: false,
			};
		case LOGOUT_ERR:
			return {
				...state,
				error: err,
				loading: false,
			};
		case UPDATE_PASS_BEGIN:
			return {
				...state,
				loading: true,
			};
		case UPDATE_PASS_SUCCESS:
			return {
				...state,
				loading: false,
				updatePassSuccess: true,
			};
		case UPDATE_PASS_ERR:
			return {
				...state,
				updateError: err,
				loading: false,
			};
		default:
			return state;
	}
};
export default AuthReducer;
