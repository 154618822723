import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { topMenuReadBegin, topMenuReadSuccess, topMenuReadErr } = actions;

const topMenuGetData = () => {
	return async (dispatch) => {
		try {
			dispatch(topMenuReadBegin());
			const response = await DataService.get('/me');
			if (response.status === 200) {
				dispatch(topMenuReadSuccess(response.data));
			} else {
				dispatch(topMenuReadErr({
					data: response.data,
					status: response.status,
				}));
			}
		} catch (err) {
			dispatch(topMenuReadErr(err));
		}
	};
};


export { topMenuGetData };
