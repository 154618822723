import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
	integrationAccountCreateBegin,
	integrationAccountCreateSuccess,
	integrationAccountCreateErr,

	integrationAccountReadBegin,
	integrationAccountReadSuccess,
	integrationAccountReadErr,

	integrationAccountUpdateBegin,
	integrationAccountUpdateSuccess,
	integrationAccountUpdateErr,

} = actions;

const integrationAccountListRead = (key, auth_id) => {
	return async (dispatch) => {
		dispatch(integrationAccountReadBegin());
		try {
			key = key.toLowerCase();
			const response = await DataService.get('/ts/' + key + '/accounts', { auth_id: auth_id });
			if (response.status !== 200) {
				dispatch(integrationAccountReadErr(response.data.message));
			} else {
				dispatch(integrationAccountReadSuccess(response.data.result));
			}
		} catch (err) {
			dispatch(integrationAccountReadErr(err));
		}
	};
};

const integrationAccountCreate = (values, key) => {
	return async (dispatch) => {
		dispatch(integrationAccountCreateBegin());
		try {
			const response = await DataService.post('/ts/' + key + '/account', values);
			if (response.status !== 200) {
				dispatch(integrationAccountCreateErr(response.data.message));
			} else {
				dispatch(integrationAccountCreateSuccess(true));
			}
		} catch (err) {
			console.log('err', err);
			dispatch(integrationAccountCreateErr(err));
		}
	};
};

export { integrationAccountListRead, integrationAccountCreate };
