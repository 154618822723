const actions = {
	SUB_CAMPAIGNS_READ_BEGIN: 'SUB_CAMPAIGNS_READ_BEGIN',
	SUB_CAMPAIGNS_READ_SUCCESS: 'SUB_CAMPAIGNS_READ_SUCCESS',
	SUB_CAMPAIGNS_READ_ERR: 'SUB_CAMPAIGNS_READ_ERR',


	subCampaignsReadBegin: () => {
		return {
			type: actions.SUB_CAMPAIGNS_READ_BEGIN,
		};
	},

	subCampaignsReadSuccess: (data) => {
		return {
			type: actions.SUB_CAMPAIGNS_READ_SUCCESS,
			data,
		};
	},

	subCampaignsReadErr: (err) => {
		return {
			type: actions.SUB_CAMPAIGNS_READ_ERR,
			err,
		};
	},
};

export default actions;
