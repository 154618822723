import {useDispatch} from "react-redux";
import {
    campaignsListAvailableUpdate,
    campaignsListBidUpdate,
    campaignsListBudgetUpdate
} from "../../redux/campaigns/actionCreator";

const useSharedFunctions = () => {
    const dispatch = useDispatch();

    const handleUpdateBid = async ({ values, campaign }) => {
        dispatch(campaignsListBidUpdate(values, campaign));
    };

    const handleUpdateBudget = async ({ values, campaign }) => {
        dispatch(campaignsListBudgetUpdate(values, campaign));
    };

    const handleUpdateSwitch = async ({ values, campaign }) => {
        dispatch(campaignsListAvailableUpdate(values, campaign));
    }

    return {
        handleUpdateBid,
        handleUpdateSwitch,
        handleUpdateBudget
    }
}

export default useSharedFunctions;